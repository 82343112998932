import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router-dom";
import * as ReactPopOver from "react-popover";
import Logo from "../../img/SocialStarhub.png";

import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
import "./styles.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import DropdownList from "./DropdownList";
import { updateUserDetails } from "../../redux/slices/auth/authSlice";
const Header = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector(({ auth }) => auth);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {}, []);
  const gotoCartPage = (url) => {
    setIsOpen(false);
    history.push(url);
  };
  const getShortName = () => {
    var shortname = "";
    try {
      var firstName = user?.data?.firstName
        ? user?.data?.firstName.charAt(0).toUpperCase()
        : "";
      var lastName = user?.data?.lastName
        ? user?.data?.lastName.charAt(0).toUpperCase()
        : "";
      shortname = firstName + lastName;
    } catch (error) {}
    return shortname;
  };
  const togglePopover = () => {
    setIsOpen(!isOpen);
    history.push("/MyProfileInfo");
  };
  const handleLogout = () => {
    localStorage.clear();
    history.push("/products");
    setIsOpen(!isOpen);
    // dispatch(updateUserDetails(null));
  };
  return (
    <>
      <div className="header-wrapper" id="headerWrapper">
        <div className="headerSection">
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="allItemsSection">
              <div className="leftsideItem">
                <img
                  onClick={() => {
                    gotoCartPage("/");
                  }}
                  src={Logo}
                />
              </div>
              <div className="centersideItem">
                {/* <input placeholder="Search Items"/>  */}
              </div>
              <div className="rightsideItem">
                {/* <span
                  className="cartIconPage"
                  onClick={() => {
                    gotoCartPage("/cart");
                  }}
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 16 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="heart-icon"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.69231 4.92308H1.84615C1.35631 4.92308 0.886769 5.11754 0.540923 5.464C0.194462 5.80984 0 6.27938 0 6.76923C0 8.47815 0 11.6431 0 14.1538C0 15.2966 0.453538 16.392 1.26154 17.2C2.06954 18.008 3.16492 18.4615 4.30769 18.4615C6.56308 18.4615 9.43692 18.4615 11.6923 18.4615C12.8351 18.4615 13.9305 18.008 14.7385 17.2C15.5465 16.392 16 15.2966 16 14.1538V6.76923C16 6.27938 15.8055 5.80984 15.4591 5.464C15.1132 5.11754 14.6437 4.92308 14.1538 4.92308H12.3077V4.30769C12.3077 1.92861 10.3791 0 8 0C5.62092 0 3.69231 1.92861 3.69231 4.30769V4.92308ZM14.7692 6.76923V14.1538C14.7692 14.9698 14.4449 15.7526 13.8683 16.3298C13.2911 16.9065 12.5083 17.2308 11.6923 17.2308C9.43692 17.2308 6.56308 17.2308 4.30769 17.2308C3.49169 17.2308 2.70892 16.9065 2.13169 16.3298C1.55508 15.7526 1.23077 14.9698 1.23077 14.1538V6.76923C1.23077 6.60615 1.29538 6.44923 1.41108 6.33415C1.52615 6.21846 1.68308 6.15384 1.84615 6.15384H14.1538C14.3169 6.15384 14.4738 6.21846 14.5889 6.33415C14.7046 6.44923 14.7692 6.60615 14.7692 6.76923ZM11.0769 4.92308V4.30769C11.0769 2.60861 9.69908 1.23077 8 1.23077C6.30092 1.23077 4.92308 2.60861 4.92308 4.30769V4.92308H11.0769Z"
                      fill="#1db954"
                    ></path>
                  </svg>
                </span> */}
                <span className="signInButtonArea">
                  {user?._id ? (
                    <>
                      <ReactPopOver
                        isOpen={isOpen}
                        body={
                          <DropdownList
                            handleLogout={handleLogout}
                            gotoNextPage={gotoCartPage}
                          />
                        }
                        place="below"
                        onOuterAction={togglePopover}
                      >
                        <span
                          className="btn-user-profile"
                          onClick={togglePopover}
                        >
                          <div className="profileIconStyles">
                            {/* {getShortName()} */}

                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="user">
                                <path
                                  id="Vector"
                                  d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                                  stroke="#555555"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  id="Vector_2"
                                  d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                                  stroke="#555555"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                            </svg>
                          </div>
                        </span>
                      </ReactPopOver>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          gotoCartPage("/signIn");
                        }}
                        className="signInButton1"
                      >
                        Log In
                      </button>
                      <button
                        onClick={() => {
                          gotoCartPage("/signUp");
                        }}
                        className="signInButton"
                      >
                        Sign Up
                      </button>
                    </>
                  )}
                </span>
                {/* {user?.data?._id ?<>
                        <span className="signInButtonArea">
                        <button onClick={()=>{gotoCartPage("/admin/createProduct")}} className="signInButton">
                                Create 
                            </button>
                        </span>
                        </>: null }

                        {user?.data?._id ?<>
                        <span className="signInButtonArea">
                        <button onClick={()=>{gotoCartPage("/admin/manageProducts")}} className="signInButton">
                              Manage 
                            </button>
                        </span>
                        </>: null } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
