import styled from "styled-components";

export const BankAccountStyled = styled.div`
  .admin-add-links-sec {
    width: 100%;
  }
  .RecentPodcasts-link-box {
    margin-top: 10px;
  }
  .daily-quote-header-text {
    display: flex;
    justify-content: space-between;
    h2 {
      // font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px; /* 135% */
      color: #000;
    }
    button {
      border-radius: 18px;
      background: #ff5733;
      color: #fff;
      // font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: uppercase;
      width: 312px;
      padding: 14px 32px;
      justify-content: center;
      border: none;
    }
  }
  .bank-account-sec-box p {
  }
  .no-bank-acc {
    border-radius: 16px;
    border: 1px solid #cfd3d7;
    height: 200px;
    padding-top: 80px;
    margin-top: 40px;
    background: #fcfcfc;
    p {
      color: var(--Neutral-400, #bfc5c9);
      text-align: center;
      /* Paragraph 02/Regular */
      // font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 187.5% */
    }
  }
`;
