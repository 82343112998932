import styled from "styled-components";

export const SaleStatusStyled = styled.div`
  .sale-status-content {
    padding: 32px;
    margin-top: 24px;
    border-radius: 16px;
    background: #fff;
    margin-top: 30px;
    h2 {
      color: var(--Project-Black, #222);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .sale-status-content-header {
    display: flex;
    justify-content: space-between;
  }
  .all-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 30px;
  }
  .box-row {
    padding: 1rem;
    width: 200px;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    background: var(--Project-White, #fff);
    box-shadow: 2px 0px 24px 5px rgba(86, 59, 255, 0.04);
    h5 {
      overflow: auto;
    }
    h6 {
      color: var(--Project-Black, #222);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    h4 {
      color: var(--Project-Black, #222);
      font-family: Roboto;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    p {
      color: var(--Project-Black, #222);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    p .red {
      color: var(--Project-Red, #d0004b);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .all-boxes1 {
    h5 {
      color: var(--Project-Black, #222);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 20px;
    }
    display: flex;
    justify-content: start;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 30px;
    > .box-row1,
    > .box-row1-box {
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #f1f1f1;

      background: var(--Project-White, #fff);
      box-shadow: 2px 0px 24px 5px rgba(86, 59, 255, 0.04);
    }
  }
  .box-row1-box {
    display: flex;
    > div {
      width: 50%;
    }
    h6 {
      color: var(--Project-Black, #222);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 10px;
    }
    h4 {
      color: var(--Project-Black, #222);
      font-family: Roboto;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .bottom-text {
    color: #787878;

    font-family: Roboto;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }
  .dashboard-graph-all {
    display: flex;
    > div {
      width: 50%;
    }
  }
`;
