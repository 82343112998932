import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import heroimage from "../../img/hero-image-2.png";
import frame565 from "../../img/Frame-565.png";
import app_Store from "../../img/App_Store.png";
import google_play from "../../img/Google_play.png";
import { HomeStyled } from "./HomeStyled";
import influencers from "../../img/Influencers.png";

// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const Home = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // const { allProducts } = useSelector(({ productCart }) => productCart);
  useEffect(() => {
    console.log("vinod");
  }, []);
  const listItems = [
    { title: "Apple Podcasts", name: "applePodcasts" },
    { title: "Spotify", name: "Spotify" },
    { title: "Google Podcasts", name: "googlePodcasts" },
    { title: "RSS", name: "RSS" },
    { title: "Bullhorn", name: "Bullhorn" },
    // { title: "Overcast", name: "Overcast" },
    // { title: "Castbox", name: "Castbox" },
  ];
  const gotoCartPage = (url) => {
    history.push(url);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
  };
  // console.log("allProducts", allProducts);
  return (
    <>
      <HomeStyled>
        <div className="home-container">
          <div className="home-content-container">
            <div className="home-content-left">
              <h2>
                Maximize Your Influence: All-in-One Extension Manager for Social
                Stars
              </h2>
              <p>
                Welcome to the ultimate hub for influencers! Streamline your
                online presence with our Extension Manager, empowering you to
                effortlessly manage social accounts, podcasts, and daily quotes.
                Sign up today to unlock a new era of influence management!
              </p>
              <button
                onClick={() => {
                  gotoCartPage("/signup");
                }}
              >
                ARE yOU a INFLUENCER? - Sign up
              </button>
              <div className="download-app-icons-list">
                <div>
                  <img src={app_Store} />
                </div>
                <div>
                  <img src={google_play} />
                </div>
              </div>
            </div>

            <div className="home-content-right">
              <div className="home-content-right-content">
                <img src={influencers}></img>
              </div>
            </div>
          </div>
        </div>
      </HomeStyled>
    </>
  );
};

export default Home;
