import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./styles.css";
import Form from "react-bootstrap/Form";

import { MyProfileInfoStyled } from "./MyProfileInfo.styled";
const initVal = {
  oldPassword: undefined,
  password: undefined,
  conformPassword: undefined,
};

const initValError = {
  oldPassword: false,
  password: false,
  conformPassword: false,
};

const initValErrorData = {
  password: "Please Enter Password",
  conformPassword: "Please Enter Conform Password",
  oldPassword: "Please Enter Old Password",
};

const ChangePassword = (props) => {
  const { handleClose, changePassSuccess } = props;
  const [signUpData, setSignUpData] = useState(initVal);
  const [errorData, setErrorData] = useState(initValError);
  const [errorDataValue, setErrorDataValue] = useState(initValErrorData);
  const [passError, setPassError] = useState(null);

  const handleChangeValue = (e) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };
    setErrorData({
      ...addr_error,
      [name]: false,
    });
    setSignUpData({
      ...addr,
      [name]: value1,
    });
  };
  const updateAccountData = () => {
    setPassError(null);
    var isValid = true;
    var errornew = { ...errorData };
    for (var key in signUpData) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    console.log("errornew", errornew);
    setErrorData({ ...errornew });

    if (signUpData?.password !== signUpData?.conformPassword) {
      isValid = false;
      setPassError(
        "Password and Confirm Password fields value must be matched."
      );
    } else if (signUpData?.password.length < 6) {
      isValid = false;
      setPassError("Password requires 6 characters minimum.");
    }

    if (isValid) {
      makeUpdateCall(signUpData);
      console.log("signUpData", signUpData);
    }
  };
  const makeUpdateCall = () => {
    console.log("signUpDatasignUpData", signUpData);
    changePassSuccess();
  };
  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-change-password"
      className="contained-modal-change-password"
      centered
    >
      <MyProfileInfoStyled>
        <Modal.Body className="modalBodychange-password">
          <div>
            <div className="change-pass-header">
              <span>Change Password</span>
              <span className="close-pass-mo" onClick={handleClose}>
                X
              </span>
            </div>
            <div className="change-password-sec-body">
              <Form.Group
                className="signup-md1"
                size="lg"
                controlId="oldPassword"
              >
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  name="oldPassword"
                  placeholder="old assword"
                  isInvalid={errorData?.oldPassword}
                  value={signUpData?.oldPassword}
                  onChange={(e) => handleChangeValue(e)}
                />
                <Form.Control.Feedback type="invalid">
                  {errorData?.oldPassword ? (
                    <>{errorDataValue?.oldPassword}</>
                  ) : null}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="signup-md1" size="lg" controlId="password">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="New Password"
                  isInvalid={errorData?.password}
                  value={signUpData?.password}
                  onChange={(e) => handleChangeValue(e)}
                />
                <Form.Control.Feedback type="invalid">
                  {errorData?.password ? <>{errorDataValue?.password}</> : null}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="signup-md1-left"
                size="lg"
                controlId="password"
              >
                <Form.Label>Conform New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="conformPassword"
                  placeholder="Conform New Password"
                  value={signUpData?.conformPassword}
                  onChange={(e) => handleChangeValue(e)}
                />
              </Form.Group>
            </div>
            {passError && <p className="errorMessage">{passError}</p>}
            <div className="change-pass-actions-btn">
              <button onClick={handleClose} className="cancel-btn-pass">
                Cancel
              </button>
              <button onClick={updateAccountData} className="change-btn-pass">
                Change Password
              </button>
            </div>
          </div>
        </Modal.Body>
      </MyProfileInfoStyled>
    </Modal>
  );
};

export default ChangePassword;
