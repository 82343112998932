import styled from "styled-components";

export const MyProfileInfoStyled = styled.div`
  .top-header-sec-my-p {
    h2 {
      color: var(--Shade-100, #000);
      /* Heading 06/H6/Small/Semibold */
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px; /* 135% */
    }
  }
  .profle-body-sec-container {
    border-radius: 16px;
    border: 1px solid #cfd3d7;

    background: #fcfcfc;
    padding: 24px;
    // min-height: 300px;
  }
  .profile-info-sec {
    p {
      margin-bottom: 12px;
    }
  }
  .logo-bg-profile span {
    height: 80px;
    width: 80px;
    display: block;
    margin: auto;
    border-radius: 80px;
    border: 4px solid #ebebeb;
    background: #fff;
    padding-top: 22px;
    margin-bottom: 20px;
    color: #ff5733;
    text-align: center;
    /* font-family: Poppins; */
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 2.24px;
  }
  .p-user-name {
    color: var(--Shade-100, #000);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
  }
  .p-user-info {
    color: var(--Shade-100, #000);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }
  .profile-change-pass-btn {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    button {
      color: var(--Shade-0, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: uppercase;
      border-radius: 18px;
      background: #ff5733;
      width: 280px;
      height: 56px;
      border: none;
    }
  }
  .edit-icon-sec-val-btn {
    text-align: right;
    button {
      height: 40px;
      width: 100px;
      padding: 8px, 24px, 8px, 24px;
      border-radius: 10px;
      border: 1px;
      color: #000;
      border: 1px solid #000;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      background: #fff;
      letter-spacing: 0.002em;
    }
  }
  .edit-account-Container {
    margin-top: 20px;
    > div {
      display: flex;
      gap: 30px;
    }
    .form-group {
      width: 50%;
    }
    input:disabled {
      opacity: 0.5;
    }
  }
  .bottom-actions-btns {
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 30px;
    display: flex;
    justify-content: end;
    .cancel-btn {
      width: 170px;
      height: 56px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.002em;
      background: #fff;
      border: 1px solid #000;
      border-radius: 4px;
    }
    .update-btn {
      width: 280px;
      height: 56px;
      border-radius: 18px;
      background: #ff5733;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.032px;
      text-transform: uppercase;
      width: 312px;
      padding: 14px 32px;
      justify-content: center;
      border: none;
    }
    button:disabled {
      background: #e2e2e2;
    }
  }
  .modalBodychange-password {
    padding: 0px;
  }
  .change-password-sec-body {
    margin-top: 30px;
    .form-group label {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 2px;
      color: #7f8b93;
    }
  }
  .change-pass-header {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .change-pass-actions-btn {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    gap: 36px;
    button {
      width: 50%;
      height: 50px;
    }
    .cancel-btn-pass {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.002em;
      background: #fff;
      border: 1px solid #000;
      border-radius: 10px;
    }
    .change-btn-pass {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.002em;
      background: #ff5733;
      color: #fff;
      font-size: 16px;
      border: none;
      border-radius: 10px;
    }
  }
  .close-pass-mo {
    cursor: pointer;
  }
  .errorMessage {
    color: red;
  }
  .edit-account-info h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
