import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import heroimage from "../../img/hero-image-2.png";
import frame565 from "../../img/Frame-565.png";
// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const Settings = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // const { allProducts } = useSelector(({ productCart }) => productCart);
  useEffect(() => {
    console.log("vinod");
  }, []);
  const listItems = [
    { title: "First Name", name: "applePodcasts" },
    { title: "Last Name", name: "Spotify" },
    { title: "Email", name: "googlePodcasts" },
    { title: "Phone Number", name: "RSS" },
    // { title: "Overcast", name: "Overcast" },
    // { title: "Castbox", name: "Castbox" },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
  };
  // console.log("allProducts", allProducts);
  return (
    <>
      <div>
        <div className="admin-add-links-sec">
          <div>
            <h2> My Profile</h2>
          </div>
          <div>
            <div>
              {listItems?.map((item) => {
                return (
                  <div className="add-link-item">
                    <p>{item?.title}</p>
                    <input
                      name={item?.name}
                      onChange={handleChange}
                      placeholder={`Enter ${item?.title}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="submit-btn-links">
            <button>Update</button>
          </div>
        </div>

        <div></div>
      </div>
    </>
  );
};

export default Settings;
