import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { FooterStyled } from "./Footer.styled";
import { bindActionCreators } from "redux";
import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import facebook_fill from "../../img/facebook-box-fill.png";
import instagram_fill from "../../img/instagram-fill.png";
import twitter_fill from "../../img/twitter-x-fill.png";
import linkedin_fill from "../../img/linkedin-box-fill.png";

import { Link, useHistory, withRouter } from "react-router-dom";
const Footer = (props) => {
  useEffect(() => {}, []);

  return (
    <>
      <FooterStyled>
        <div className="extension-footer-container">
          <div className="extension-footer-top">
            <div className="footer-top-left">
              <ul>
                <li>Terms and Conditions</li>
                <li>Privacy policy</li>
                <li>Contact Us</li>
              </ul>
            </div>
            <div className="footer-top-right">
              <div>Follow us on :</div>
              <div>
                <ul>
                  <li>
                    <img src={facebook_fill} />
                  </li>
                  <li>
                    <img src={instagram_fill} />
                  </li>
                  <li>
                    <img src={twitter_fill} />
                  </li>
                  <li>
                    <img src={linkedin_fill} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="all-rights-reser">
            <span>© 2023 SocialStarhub All Rights Reserved</span>
          </div>
        </div>
      </FooterStyled>
    </>
  );
};

export default Footer;
