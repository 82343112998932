import React, { useEffect, useState } from "react";
import { DailyQuoteStyled } from "./DailyQuote.styled";
import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import doublequotesl from "../../img/double-quotes-l.png";
import frame565 from "../../img/double-quotes-r.png";
import {
  getAllEvents,
  saveUserDailyQuotes,
  updateUserDailyQuotes,
  getAllDailyQuotes,
  saveUserEvents,
  getDailyQuoteById,
} from "../../redux/slices/auth/authService";
import delete_img from "../../img/delete-bin-fill.png";

import Loader from "./../../components/Common/Loader";
import CustomModal from "./../../components/CustomModel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const itemsList = [{ date: "", text: "" }];
// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const DailyQuote = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const [dailyQuoteVal, setDailyQuoteVal] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [formData, setFormData] = useState({});
  const [quoteItemsList, setQuoteItemsList] = useState(itemsList);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { allEvents, user, dailyQuoteDetails } = useSelector(
    ({ auth }) => auth
  );

  // const { allProducts } = useSelector(({ productCart }) => productCart);
  useEffect(() => {
    console.log("vinod");
    getDailtQuotesData();
  }, []);
  useEffect(() => {
    console.log("allEvents", allEvents);
    if (dailyQuoteDetails?.data?._id) {
      const finalData = dailyQuoteDetails?.data;
      console.log("finalData", finalData);

      let prevList = [];
      finalData?.items?.map((item) => {
        prevList.push({ ...item });
        console.log("item", item);
      });
      setQuoteItemsList(prevList);
      setFormData(finalData);
    }
  }, [dailyQuoteDetails]);
  const getDailtQuotesData = async () => {
    await dispatch(getDailyQuoteById({ userId: user?._id }));
  };
  const handleItemDelete = (index) => {
    const pplistItems = [...quoteItemsList];
    // pplistItems.push({);
    pplistItems.splice(index, 1);
    console.log("pplistItems", pplistItems);
    setQuoteItemsList(pplistItems);
  };
  const handleAddNewOne = () => {
    const pList = [...quoteItemsList];
    pList.push({ date: "", text: "" });
    setQuoteItemsList(pList);
    setUpdateState(!updateState);
  };
  const handleChange = (e, index) => {
    console.log("eeeee", e);
    const { name, value } = e.target;
    const pList = quoteItemsList;
    pList[index].text = value;
    setQuoteItemsList(pList);

    setUpdateState(!updateState);
  };
  const handleSubmit1 = () => {
    setShowWarningMessage(true);
  };
  const handleSubmit = async () => {
    // const prevV = {
    //   items: quoteItemsList,
    //  };
    const body = {
      items: quoteItemsList,
      userId: user?._id,
    };
    if (quoteItemsList[0]?.text) {
      // // prevV.DailyQuote = dailyQuoteVal;
      // delete prevV?.event_time;
      // delete prevV?.__v;
      // delete prevV?._id;
      setIsLoader(true);
      console.log("bodybody", body);
      if (formData?._id) {
        body._id = formData?._id;
        await dispatch(updateUserDailyQuotes(body));
        await getDailtQuotesData();
        setIsLoader(false);
        setShowSuccessMessage(true);
      } else {
        await dispatch(saveUserDailyQuotes(body));
        await getDailtQuotesData();
        setIsLoader(false);
        setShowSuccessMessage(true);
      }
    }
  };
  const handleDate = (date, index) => {
    const sDate = moment(date).format("MM-DD-YYYY");
    const pList = quoteItemsList;
    pList[index].date = sDate;
    setQuoteItemsList(pList);
    console.log("datedate", sDate, date, sDate);
    setUpdateState(!updateState);
    // formik.values.dateOfBirth = moment(date).toISOString(true);
  };

  console.log("quoteItemsList", quoteItemsList);
  return (
    <>
      <DailyQuoteStyled>
        <div>
          <CustomModal
            show={showWarningMessage}
            titleText={"Are you sure do you want to add quote?"}
            deleteText="ADD"
            cancelText=""
            onDelete={() => {
              setShowWarningMessage(false);
              handleSubmit();
              // history.push("/jobcardsummary");
            }}
          />
          <CustomModal
            show={showSuccessMessage}
            titleText={"Your quote has been successfully added."}
            deleteText="OKAY"
            cancelText=""
            onDelete={() => {
              setShowSuccessMessage(false);
              // history.push("/jobcardsummary");
            }}
          />

          {isLoader && <Loader />}

          <div className="admin-add-links-sec">
            <div className="daily-quote-header-text">
              <h2> Daily Quote</h2>
            </div>
            {quoteItemsList?.map((item, index) => {
              console.log("item?.date", item?.date);
              return (
                <div className="daily-quote-sec">
                  <h4>Daily Quote {index + 1}</h4>
                  {index > 0 && (
                    <div className="add-link-item-item-sec-dalete">
                      <span
                        onClick={() => {
                          handleItemDelete(index);
                        }}
                      >
                        <img src={delete_img} />
                      </span>
                    </div>
                  )}
                  <div className="daily-quote-sec-dte">
                    <DatePicker
                      value={item?.date ?? "Select Date"}
                      name="dateOfBirth"
                      className="profile-dateOfBirth automate-pdob"
                      placeholder="Enter Date of Birth"
                      onChange={(e) => {
                        handleDate(e, index);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      format="MM/dd/yyyy"
                      showLeadingZeros={true}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      onYearChange={(e) => {
                        handleDate(e, index);
                      }}
                      onMonthChange={(e) => {
                        handleDate(e, index);
                      }}
                    />
                  </div>

                  <textarea
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                    id="dailyQuoteField"
                    name="dailyQuoteField"
                    autoComplete="false"
                    placeholder="Write Quote here..."
                    value={item?.text}
                    maxLength={2000}
                  >
                    {item?.date}
                  </textarea>
                  <div className="prev-qua-img">
                    <img src={frame565} />
                  </div>
                  {/* <div className="submit-btn-links">
                    <button
                      // disabled={dailyQuoteVal?.length === 0 ? false : true}
                      onClick={handleSubmit1}
                    >
                      Edit
                    </button>
                  </div> */}
                  <div>
                    {" "}
                    <span className="content-length-section">
                      {item?.text?.length}/2000{" "}
                    </span>
                  </div>
                </div>
              );
            })}
            {quoteItemsList?.length <= 2 && (
              <div className="submit-btn-links-add">
                <button
                  // disabled={dailyQuoteVal?.length === 0 ? false : true}
                  onClick={handleAddNewOne}
                >
                  + Add New Daily Quate
                </button>
              </div>
            )}

            <div className="submit-btn-links-save">
              <button
                // disabled={dailyQuoteVal?.length === 0 ? false : true}
                onClick={handleSubmit1}
              >
                Save
              </button>
            </div>
          </div>

          {/* <div className="prev-quates">
            <h5>Last 3 days Quotes</h5>
            <div className="prev-quates-sec">
              <div className="prev-quates-list">
                {listI?.map((item) => {
                  return (
                    <div className="prev-quates-list-row">
                      <div className="prev-quates-list-row-top">
                        <div>
                          <img src={doublequotesl} />
                        </div>
                        <div>
                          <h6>Daily Quote</h6>
                          <p>18 Jan 2024</p>
                        </div>
                      </div>
                      <div className="prev-quates-list-row-bottom">
                        <p>{dailyQuoteVal}</p>
                        <div className="prev-qua-img">
                          <img src={frame565} />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div> */}
        </div>
      </DailyQuoteStyled>
    </>
  );
};

export default DailyQuote;
