import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import Signin from "../pages/Signin";
import SignUp from "../pages/Signin/SignUp";
import MyProfile from "../pages/MyProfile";

import Settings from "../pages/Settings";
import AddLinks from "../pages/AddLinks";
import BankAccount from "../pages/BankAccount";
import MyProfileInfo from "../pages/MyProfileInfo";
import DailyQuote from "../pages/DailyQuote";
import RecentPodcasts from "../pages/RecentPodcasts";
import ExtensionDashboard from "../pages/ExtensionDashboard";
import { useLocation } from "react-router-dom";
import YahooDailyReports from "../pages/YahooDailyReports";
import ReportsDashboard from "../pages/ReportsDashboard";
const MainRoutes: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      {/*Route exact path="/" component={Home} />*/}

      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/signin" component={Signin} />
      <Route exact path="/signUp" component={SignUp} />
      <Route exact path="/myProfile" component={MyProfile} />
      <Route exact path="/AddLinks" component={AddLinks} />
      <Route exact path="/Settings" component={Settings} />
      <Route exact path="/MyProfileInfo" component={MyProfileInfo} />
      <Route exact path="/dailyQuote" component={DailyQuote} />
      <Route exact path="/RecentPodcasts" component={RecentPodcasts} />
      <Route exact path="/bankAccount" component={BankAccount} />
      <Route exact path="/extensionDashboard" component={ExtensionDashboard} />
      <Route exact path="/reportsDashboard" component={ReportsDashboard} />

      <Route
        exact
        path="/saveYahooDailyReports"
        component={YahooDailyReports}
      />
    </Switch>
  );
};

export default MainRoutes;
