import React, { useEffect, useState } from "react";
import { BankAccountStyled } from "./BankAccount.styled";
import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import delete_img from "../../img/delete-bin-fill.png";
import frame565 from "../../img/Frame-565.png";
import {
  saveUserProdCasts,
  getAllProdCasts,
} from "../../redux/slices/auth/authService";
import Loader from "./../../components/Common/Loader";
import CustomModal from "./../../components/CustomModel";

// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const BankAccount = () => {
  const listItems = [
    { podcastTitle: "Recent Podcasts 1", podcastLink: "Podcasts1", image: "" },
    { podcastTitle: "", podcastLink: "", image: "" },
    { podcastTitle: "", podcastLink: "", image: "" },
  ];

  const [addressEnabled, setAddressEnabled] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [listOfItems, setListOfItems] = useState([]);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { allProdCasts } = useSelector(({ auth }) => auth);

  // const { allProducts } = useSelector(({ productCart }) => productCart);
  useEffect(() => {
    console.log("vinod", listItems);
    dispatch(getAllProdCasts());

    setListOfItems(listItems);
  }, []);
  useEffect(() => {
    console.log("allProdCasts", allProdCasts);
    if (allProdCasts?.data?._id) {
      const finalData = allProdCasts?.data;
      console.log("finalData", finalData);
      // const prevV = { ...formData };
      // prevV[name] = value;
      setFormData(finalData);
    }
  }, [allProdCasts]);

  const addNewRecord = () => {
    const pplistItems = [...listOfItems];
    pplistItems.push({ podcastTitle: "", podcastLink: "", image: "" });
    console.log("pplistItems", pplistItems);
    setListOfItems(pplistItems);
  };

  const handleItemDelete = (index) => {
    const pplistItems = [...listOfItems];
    // pplistItems.push({);
    pplistItems.splice(index, 1);
    console.log("pplistItems", pplistItems);
    setListOfItems(pplistItems);
  };
  const handleChange = (e, index) => {
    console.log("index", index);
    const { name, value } = e.target;
    // const prevV = { ...formData };
    // prevV[name] = value;
    // setFormData(prevV);

    const pplistItems = [...listOfItems];
    pplistItems[index][name] = value;
    setListOfItems(pplistItems);
  };
  const handleSubmit1 = async () => {
    setShowWarningMessage(true);
  };

  const handleSubmit = async () => {
    delete formData?.event_time;
    delete formData?.__v;
    delete formData?._id;
    setIsLoader(true);
    await dispatch(saveUserProdCasts(formData));
    await dispatch(getAllProdCasts());
    setIsLoader(false);
    setShowSuccessMessage(true);
  };

  console.log("formData", formData);
  console.log("listOfItems", listOfItems);

  return (
    <>
      <BankAccountStyled>
        <div>
          <CustomModal
            show={showWarningMessage}
            titleText={"Are you sure do you want to Add links?"}
            deleteText="ADD"
            cancelText=""
            onDelete={() => {
              setShowWarningMessage(false);
              handleSubmit();
              // history.push("/jobcardsummary");
            }}
          />
          <CustomModal
            show={showSuccessMessage}
            titleText={"Your links has been successfully added."}
            deleteText="OKAY"
            cancelText=""
            onDelete={() => {
              setShowSuccessMessage(false);
              // history.push("/jobcardsummary");
            }}
          />

          {isLoader && <Loader />}
          <div className="admin-add-links-sec">
            <div className="daily-quote-header-text">
              <div>
                <h2> Bank Account</h2>
              </div>
              <div>
                <button onClick={addNewRecord}>+ Add Bank Account</button>
              </div>
            </div>
            <div className="bank-account-sec-container">
              <div className="bank-account-sec-box"></div>
              <div>
                <div className="no-bank-acc">
                  <p>No Bank Accounts</p>
                </div>
              </div>
              {/* <div className="submit-btn-links">
              <button onClick={handleSubmit1}>ADD</button>
            </div> */}
            </div>
          </div>
        </div>
      </BankAccountStyled>
    </>
  );
};

export default BankAccount;
