import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import heroimage from "../../img/hero-image-2.png";
import frame565 from "../../img/Frame-565.png";
import app_Store from "../../img/App_Store.png";
import google_play from "../../img/Google_play.png";
import { DashboardStyled } from "./DashboardStyled";
import influencers from "../../img/Influencers.png";
import SaleStatus from "./SaleStatus/SaleStatus";
import { SaleStatusStyled } from "./SaleStatus/SaleStatus.styled";
import { getAuthToken } from "./../../Scenes/common";
import { DatePicker, Space } from "antd";
import moment from "moment";

import "antd/dist/antd.css";
import { Table } from "antd";
import {
  getAllPriceLineFeedsApi,
  getAllYahooFeedsApi,
  createUserClicksApi1,
  createUserClicksApi2,
  getAllPricelineSales,
} from "./../../redux/slices/auth/authService";

import {
  handleGetAuthTokenAPI,
  handleDailyRevenueAPI,
  handleMarketRevenueAPI,
  handleDeviceRevenueAPI,
  handleHourlyRevenueAPI,
  getDateRangeReport,
} from "./../../redux/slices/extensionDashboard/extensionDashboardService";
import { getExtansionTokenPost } from "./../../Scenes/common";
import Loader from "../../components/Common/Loader";
// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const ReportsDashboard = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const [allData, setAllData] = useState([]);
  const [totalClicks, setTotalClicks] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartedDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [finalDataNew, setFinalDataNew] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { allYahooFeedsData, allPriceLineFeedsData } = useSelector(
    ({ auth }) => auth
  );
  const {
    extensionUserInfo,
    dailyRevenueInfo,
    deviceRevenueInfo,
    extensionReports,
    marketRevenueInfo,
  } = useSelector((stateRR) => stateRR.extensionDashboard);
  console.log("extensionReports", extensionReports);
  useEffect(() => {
    // getAllClicksInfoData();
    const datav = moment(new Date()).subtract(1, "days").format("YYYY-MM-DD");
    setSelectedDate(datav);
    // getLatestDataHandle(datav);
  }, []);
  useEffect(() => {
    var bidded_clicks = 0;
    var bidded_results = 0;
    var bidded_searches = 0;
    var coverage = 0;
    var ctr = 0;
    var partner_rev_share = 0;
    var searches = 0;
    var tq_score = 0;

    extensionReports?.reportsData?.map((item) => {
      bidded_clicks = item?.bidded_clicks + bidded_clicks;
      bidded_results = item?.bidded_results + bidded_results;
      bidded_searches = item?.bidded_searches + bidded_searches;
      coverage = item?.coverage + coverage;
      ctr = item?.ctr + ctr;
      partner_rev_share = item?.partner_rev_share + partner_rev_share;
      searches = item?.searches + searches;
      tq_score = item?.tq_score + tq_score;
    });

    const finalData1 = {
      bidded_clicks,
      bidded_results,
      bidded_searches,
      coverage,
      ctr,
      partner_rev_share,
      searches,
      tq_score,
    };
    const finalData = [];
    finalData.push(finalData1);
    setFinalDataNew(finalData);
  }, [extensionReports?.reportsData]);

  const gotoCartPage = (url) => {
    history.push(url);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
  };
  const onChangeStart = (datev) => {
    const date2 = new Date(datev);
    console.log("date2", date2);
    console.log("onChange", datev);
    const datav = moment(datev || new Date()).format("YYYY-MM-DD");
    console.log("datav", datav);
    setStartedDate(datav);
  };
  const onChangeEnd = (datev) => {
    const date2 = new Date(datev);
    console.log("date2", date2);
    console.log("onChange", datev);
    const datav = moment(datev || new Date()).format("YYYY-MM-DD");
    console.log("datav", datav);
    setEndDate(datav);
  };
  const handleGetReports = async () => {
    if (startDate && endDate) {
      setIsLoading(true);
      await dispatch(
        getDateRangeReport({ startDate: startDate, endDate: endDate })
      );
      setIsLoading(false);
    }
  };
  const allData1 = allData?.sort((a, b) => {
    return b.count - a.count;
  });

  const dailyRe = [
    {
      title: "Bidded Clicks",
      dataIndex: "bidded_clicks",
      width: 180,
    },
    {
      title: "Bidded Results",
      dataIndex: "bidded_results",
      width: 180,
    },
    {
      title: "Bidded Searches",
      dataIndex: "bidded_searches",
      width: 180,
    },

    {
      title: "Coverage",
      dataIndex: "coverage",
      width: 150,
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      width: 150,
    },
    {
      title: "Partner rev share",
      dataIndex: "partner_rev_share",
      width: 200,
    },
    {
      title: "Searches",
      dataIndex: "searches",
      width: 150,
    },
    {
      title: "tq_score",
      dataIndex: "tq_score",
      width: 150,
    },
  ];

  console.log("allData1", allData1);
  const scrollObj = { x: 1500, y: 500 };
  return (
    <>
      <DashboardStyled>
        <div className="home-container">
          {isLoading && <Loader />}
          <label>Start Date</label>
          <DatePicker onChange={onChangeStart} />
          <label>End Date</label>
          <DatePicker onChange={onChangeEnd} />
          <div className="extension-table-view extension-dashboard-container">
            <h3>Start Date: {startDate}</h3>
            <h3>End Date: {endDate}</h3>
            <div className="save-reports">
              <button onClick={handleGetReports}>Get Revenue</button>
            </div>

            <div>
              <h2>Daily Revenue Info</h2>
              <div>
                <Table
                  pagination={false}
                  dataSource={finalDataNew}
                  columns={dailyRe}
                  scroll={scrollObj}
                  // rowClassName={(record, index) => `row_selected_priority`}
                />
              </div>
            </div>

            <div>
              <h2>Daily Revenue Info</h2>
              <div>
                <Table
                  pagination={false}
                  dataSource={extensionReports?.reportsData}
                  columns={dailyRe}
                  scroll={scrollObj}
                  // rowClassName={(record, index) => `row_selected_priority`}
                />
              </div>
            </div>
          </div>
        </div>
      </DashboardStyled>
    </>
  );
};

export default ReportsDashboard;
