import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SideMenuStyled } from "./SideMenu.styled";
import daily_quite from "./../../img/chat-quote-fill.png";
import mic_fill from "./../../img/mic-fill.png";
import link_img from "./../../img/link.png";
import bank_gill from "./../../img/bank-fill.png";
import user_fill from "./../../img/user-6-fill.png";
import homeicon from "./../../img/Profile_pp.png";
import { useHistory } from "react-router";

// import teamWorkImg from "./../../img/teamwork.png";
// import teamWorkImg from "./../../img/teamwork.png";
const Sidebar = (props: any) => {
  const [sidebar, setSidebar] = useState(false);
  // const dispatch = useDispatch();
  const history = useHistory();
  const showSidebar = () => setSidebar(!sidebar);
  //   left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  //   Home
  //   Service Management
  //   Lead Management
  //   Booking Management
  //   AMC Management
  //   Users List
  //  Change Password

  // Jobcard Summary
  // Create Job Card
  // Items Received List
  // Default IssuesOption List
  // Additional Requirement List
  // Brand List
  // Product List
  // Actiontaken List
  // Engineer Observation List
  // Customer Note List
  // Postal Code List
  // Area / Ward List
  console.log("props", props);

  const gotoNewPage = (url: any) => {
    if (url === "/createjobcard") {
      window.location.href = url;
    } else if (url) {
      history.push(url);
    }
  };
  const menuItems = [
    {
      title: "Daily Quote",
      link: "dailyQuote",
      img: daily_quite,
    },
    {
      title: "Recent Podcasts",
      link: "recentPodcasts",
      img: mic_fill,
    },
    {
      title: "lInks",
      link: "addLinks",
      img: link_img,
    },
    {
      title: "Bank Account",
      link: "bankAccount",
      img: bank_gill,
    },
    {
      title: "My Account",
      link: "MyProfileInfo",
      img: user_fill,
    },
    {
      title: "Extension Dashboard",
      link: "extensionDashboard",
      img: user_fill,
    },
  ];
  const selectedUrl = window.location.pathname;
  console.log("selectedUrl", selectedUrl);
  return (
    <>
      <SideMenuStyled>
        <div className="menu-sec-add-list">
          {menuItems?.map((item: any) => {
            return (
              <div
                className={
                  selectedUrl === `/${item?.link}`
                    ? "selected-link-item"
                    : "row-link-item"
                }
              >
                <span
                  onClick={() => {
                    gotoNewPage(item?.link);
                  }}
                >
                  <span>
                    <img src={item?.img} />
                  </span>
                  {item?.title}
                </span>
              </div>
            );
          })}
        </div>
      </SideMenuStyled>
    </>
  );
};

export default Sidebar;
