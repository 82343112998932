import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  loginUser,
  signUpUser,
  getProdcastById,
  getAllEvents,
  getEventsById,
  getDailyQuoteById,
  getUserDetailsById,
  getSignedUrlApi,
} from "./authService";

export interface AuthState {
  loading: boolean;
  user: any;
  error: any;
  signUpResponse: any;
  allProdCasts: any;
  allEvents: any;
  dailyQuoteDetails: any;
}
const userData: string | null = localStorage.getItem("user");
const initialState: AuthState = {
  loading: false,
  user: userData !== null ? JSON.parse(userData) : {},
  error: null,
  signUpResponse: {},
  allProdCasts: {},
  allEvents: {},
  dailyQuoteDetails: {},
};

export const authSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state: AuthState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateUserDetails: (state: AuthState, action: PayloadAction<boolean>) => {
      state.user = action.payload;
    },
    updateErrorMessage: (state: AuthState, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(loginUser.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      loginUser.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        if (action?.payload?.data?._id) {
          state.user = action.payload?.data;
          var userInfo = action.payload?.data;

          localStorage.setItem("user", JSON.stringify(userInfo));
        }
        // console.log("action", action);
      }
    );
    builder.addCase(loginUser.rejected, (state: AuthState, action: any) => {
      state.loading = false;
      // console.log("action", action);
      state.user = {};
      state.error = action.error?.message ?? "Something went wrong";
    });

    builder.addCase(signUpUser.pending, (state: any) => {
      state.loading = true;
      state.error = null;
      state.signUpResponse = null;
    });
    builder.addCase(
      signUpUser.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        var userInfo = action.payload?.user;
        if (userInfo) {
          state.signUpResponse = action.payload;
          state.user = userInfo;
          localStorage.setItem("user", JSON.stringify(userInfo));
        }
      }
    );
    builder.addCase(signUpUser.rejected, (state: AuthState, action: any) => {
      console.log({ action });
      state.loading = false;
      state.signUpResponse = null;
      state.error = action.error?.message ?? "Something went wrong";
    });
    builder.addCase(getProdcastById.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getProdcastById.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allProdCasts = action.payload;
      }
    );
    builder.addCase(
      getProdcastById.rejected,
      (state: AuthState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    builder.addCase(getAllEvents.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllEvents.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allEvents = action.payload;
      }
    );
    builder.addCase(getAllEvents.rejected, (state: AuthState, action: any) => {
      console.log({ action });
      state.loading = false;
      state.error = action.error?.message ?? "Something went wrong";
    });
    //getEventsById
    builder.addCase(getEventsById.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getEventsById.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allEvents = action.payload;
      }
    );
    builder.addCase(getEventsById.rejected, (state: AuthState, action: any) => {
      console.log({ action });
      state.loading = false;
      state.error = action.error?.message ?? "Something went wrong";
    });
    //getDailyQuoteById
    builder.addCase(getDailyQuoteById.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getDailyQuoteById.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.dailyQuoteDetails = action.payload;
      }
    );
    builder.addCase(
      getDailyQuoteById.rejected,
      (state: AuthState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getUserDetailsById
    builder.addCase(getUserDetailsById.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getUserDetailsById.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.user = action.payload?.data;
        var userInfo = action.payload?.data;
        localStorage.setItem("user", JSON.stringify(userInfo));
      }
    );
    builder.addCase(
      getUserDetailsById.rejected,
      (state: AuthState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getSignedUrlApi
  },
});

export const {
  setLoading,
  updateUserDetails,
  updateErrorMessage,
} = authSlice.actions;

export default authSlice.reducer;
