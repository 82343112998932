export const API_BASE = "/api";
export const GRAPH_BASE = "https://graph.facebook.com/v6.0";

let SERVER_IP_URL;
if (typeof window !== "undefined") {
  if (window.location.hostname === "localhost") {
    // SERVER_IP_URL  = "http://localhost:8000/api";
    // SERVER_IP_URL = "http://13.233.1.1:4000/home";
    SERVER_IP_URL = "http://localhost:4000/home";
    // SERVER_IP_URL = "http://13.233.1.1:4000/home";
  } else {
    SERVER_IP_URL = "https://trendsparkler.com/home";
  }
}

export const SERVER_IP = SERVER_IP_URL;
