import styled from "styled-components";

export const RecentPodcastStyled = styled.div`
  .daily-quote-sec textarea {
    width: 100%;
    background-color: initial;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    /* font-family: Montserrat; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 120px;
    line-height: 28px;
    padding: 10px 15px;
  }
  .admin-add-links-sec {
    width: 100%;
  }

  .image-link-sec-item {
    text-align: center;

    padding: 10px;
    span {
      color: var(--Shade-100, #000);
      text-align: center;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
    img {
      max-width: 100%;
    }
  }
  .image-link-sec-item-new {
    padding-top: 42px;
  }
  .RecentPodcasts-link-box {
    margin-top: 10px;
  }
  .daily-quote-header-text {
    // margin-bottom: 20px;
  }
  .content-length-section {
    bottom: -18px;
    float: right;
    right: 2px;
    color: #979797;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }
  .add-link-item p {
    margin-bottom: 0px;
  }
  .add-link-item {
    margin-top: 30px;
    border-radius: 16px;
    border: 1px solid #cfd3d7;

    background: #fcfcfc;
    padding: 24px;
  }
  .daily-quote-header-text {
    display: flex;
    justify-content: space-between;
    h2 {
      // font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px; /* 135% */
      color: #000;
    }
    button {
      border-radius: 18px;
      background: #ff5733;
      color: #fff;
      // font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: uppercase;
      width: 312px;
      padding: 14px 32px;
      justify-content: center;
      border: none;
    }
  }
  .add-link-item-bottom {
    display: flex;
    gap: 24px;
    .row-left {
      width: 180px;
    }
    .row-right {
      width: 100%;
    }
  }

  .add-link-item {
    h4 {
      color: var(--Shade-100, #000);
      // font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 177.778% */
    }
    p {
      color: var(--Neutral-700, #7f8b93);
      // font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
      margin-bottom: 4px;
    }
    .manditary-field {
      color: var(--Error-Colour-200, #ef4444);
      // font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .input-box {
      color: var(--Neutral-300, #cfd3d7);
      // font-family: Poppins;
      color: var(--Shade-100, #000);
      // font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */

      background: #fff;
      border-radius: 18px;
      border: 1px solid #cfd3d7;
      height: 52px;
      padding-left: 18px;
    }
  }
  .row-left {
    border-radius: 5px;
    border: 1px solid var(--Neutral-100, #eceeef);
    background: var(--Neutral-50, #f8f7fa);
  }
  .add-link-item-item-sec {
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
`;
