import styled from "styled-components";

export const HomeStyled = styled.div`
  .home-content-container {
    display: flex;
    gap: 150px;
    margin-top: 20px;
  }
  .download-app-icons-list {
    margin-top: 24px;
    display: flex;
    gap: 20px;
    > div {
      width: 50%;
    }
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  .home-content-left {
    width: 55%;
    padding-top: 150px;
    h2 {
      color: #000;
      font-size: 43px;
      font-style: normal;
      font-weight: 600;
      line-height: 58px; /* 134.884% */
      margin-bottom: 16px;
    }
    p {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 185.714% */
      margin-bottom: 0px;
    }
    button {
      margin-top: 24px;
      border-radius: 18px;
      background: #ff5733;
      color: var(--Shade-0, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: capitalize;
      border: none;
      height: 64px;
      width: 100%;
      text-transform: uppercase;
    }
  }
  .home-content-right {
    width: 45%;

    border-radius: 16px;
    background: #feefeb;
    padding: 46px 36px;

    img {
      max-width: 100%;
    }
  }
  .home-content-container {
  }
  .home-content-right-content {
  }
`;
