import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import heroimage from "../../img/hero-image-2.png";
import frame565 from "../../img/Frame-565.png";
import app_Store from "../../img/App_Store.png";
import google_play from "../../img/Google_play.png";
import { DashboardStyled } from "./DashboardStyled";
import influencers from "../../img/Influencers.png";
import SaleStatus from "./SaleStatus/SaleStatus";
import { SaleStatusStyled } from "./SaleStatus/SaleStatus.styled";
import { getAuthToken } from "./../../Scenes/common";
import { DatePicker, Space } from "antd";
import moment from "moment";

import "antd/dist/antd.css";
import { Table } from "antd";
import {
  getAllPriceLineFeedsApi,
  getAllYahooFeedsApi,
  createUserClicksApi1,
  createUserClicksApi2,
  getAllPricelineSales,
} from "./../../redux/slices/auth/authService";

import {
  handleGetAuthTokenAPI,
  handleDailyRevenueAPI,
  handleMarketRevenueAPI,
  handleDeviceRevenueAPI,
  handleHourlyRevenueAPI,
} from "./../../redux/slices/extensionDashboard/extensionDashboardService";
import { getExtansionTokenPost } from "./../../Scenes/common";
// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const ExtensionDashboard = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const [allData, setAllData] = useState([]);
  const [totalClicks, setTotalClicks] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { allYahooFeedsData, allPriceLineFeedsData } = useSelector(
    ({ auth }) => auth
  );
  const {
    extensionUserInfo,
    dailyRevenueInfo,
    deviceRevenueInfo,
    marketRevenueInfo,
  } = useSelector((stateRR) => stateRR.extensionDashboard);
  console.log("extensionUserInfo", extensionUserInfo);
  console.log("dailyRevenueInfo", dailyRevenueInfo);
  console.log("deviceRevenueInfo", deviceRevenueInfo);
  console.log("marketRevenueInfo", marketRevenueInfo);
  useEffect(() => {
    // getAllClicksInfoData();
    const datav = moment(new Date()).subtract(1, "days").format("YYYY-MM-DD");
    setSelectedDate(datav);
    // getLatestDataHandle(datav);
  }, []);
  useEffect(() => {
    getLatestDataHandle();
  }, [selectedDate]);

  useEffect(() => {
    var linst1 = [];
    var count = 0;
    allPriceLineFeedsData?.data?.map((item) => {
      linst1.push(item);
      count += item?.count;
    });
    setAllData(linst1);
    setTotalClicks(count);
  }, [allPriceLineFeedsData?.data]);

  const getLatestDataHandle = async () => {
    const dates1 = selectedDate;
    // dispatch(getAllYahooFeedsApi());
    // const token = getAuthToken();
    // if (!token) {
    // console.log("tokentoken", token);
    if (dates1) {
      const body1 = {
        email: "vinod@theredclub.com",
        password: "Origami@1",
      };
      if (!getExtansionTokenPost()) {
        const resp = await dispatch(handleGetAuthTokenAPI(body1));
      }
      // const resp = await dispatch(handleGetAuthTokenAPI(body1));
      // console.log("respresp", resp);
      // }
      const resp3 = dispatch(handleDailyRevenueAPI({ startDate: dates1 }));
      const resp1 = dispatch(handleMarketRevenueAPI({ startDate: dates1 }));
      const resp2 = dispatch(handleDeviceRevenueAPI({ startDate: dates1 }));
    }
    // const resp4 = dispatch(handleHourlyRevenueAPI({}));

    // getTrackings();
  };

  // const getAllClicksInfoData = async () => {
  //   // dispatch(getAllYahooFeedsApi());
  //   const token = getAuthToken();
  //   if (!token) {
  //     console.log("tokentoken", token);
  //     const body1 = {
  //       email: "vinod@origami.dev",
  //       password: "July@baby1",
  //     };
  //     await dispatch(createUserClicksApi1(body1));
  //   }
  //   getTrackings();
  // };
  // const getTrackings = () => {
  //   //   const body1 = {
  //   //   email: "vinod@origami.dev",
  //   //   password: "July@baby1",
  //   // };
  //   dispatch(createUserClicksApi2());
  // };
  // const listItems = [
  //   { title: "Apple Podcasts", name: "applePodcasts" },
  //   { title: "Spotify", name: "Spotify" },
  //   { title: "Google Podcasts", name: "googlePodcasts" },
  //   { title: "RSS", name: "RSS" },
  //   { title: "Bullhorn", name: "Bullhorn" },
  //   // { title: "Overcast", name: "Overcast" },
  //   // { title: "Castbox", name: "Castbox" },
  // ];
  const gotoCartPage = (url) => {
    history.push(url);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
  };
  const onChange = (datev) => {
    const date2 = new Date(datev);
    console.log("date2", date2);
    console.log("onChange", datev);
    const datav = moment(datev || new Date()).format("YYYY-MM-DD");
    console.log("datav", datav);
    setSelectedDate(datav);
  };
  const allData1 = allData?.sort((a, b) => {
    return b.count - a.count;
  });

  const dailyRe = [
    {
      title: "Date",
      dataIndex: "y_date",
      width: 150,
      fixed: "left",
    },
    {
      title: "Affiliate",
      dataIndex: "affiliate",
      width: 150,
    },
    {
      title: "User Tag",
      dataIndex: "user_tag",
      width: 150,
    },
    {
      title: "Bidded Clicks",
      dataIndex: "bidded_clicks",
      width: 180,
    },
    {
      title: "Bidded Results",
      dataIndex: "bidded_results",
      width: 180,
    },
    {
      title: "Bidded Searches",
      dataIndex: "bidded_searches",
      width: 180,
    },
    {
      title: "Composition",
      dataIndex: "composition",
      width: 150,
    },
    {
      title: "Coverage",
      dataIndex: "coverage",
      width: 150,
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      width: 150,
    },
    {
      title: "Partner rev share",
      dataIndex: "partner_rev_share",
      width: 200,
    },
    {
      title: "Searches",
      dataIndex: "searches",
      width: 150,
    },
    {
      title: "tq_score",
      dataIndex: "tq_score",
      width: 150,
    },
  ];

  const deviceRevenue = [
    {
      title: "Date",
      dataIndex: "y_date",
      width: 150,
      fixed: "left",
    },
    {
      title: "device_type",
      dataIndex: "device_type",
      width: 150,
    },
    {
      title: "Affiliate",
      dataIndex: "affiliate",
      width: 150,
    },
    {
      title: "User Tag",
      dataIndex: "user_tag",
      width: 150,
    },
    {
      title: "Bidded Clicks",
      dataIndex: "bidded_clicks",
      width: 180,
    },
    {
      title: "Bidded Results",
      dataIndex: "bidded_results",
      width: 180,
    },
    {
      title: "Bidded Searches",
      dataIndex: "bidded_searches",
      width: 180,
    },
    {
      title: "Composition",
      dataIndex: "composition",
      width: 150,
    },
    {
      title: "Coverage",
      dataIndex: "coverage",
      width: 150,
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      width: 150,
    },
    {
      title: "Partner rev share",
      dataIndex: "partner_rev_share",
      width: 200,
    },
    {
      title: "Searches",
      dataIndex: "searches",
      width: 150,
    },
    {
      title: "tq_score",
      dataIndex: "tq_score",
      width: 150,
    },
  ];

  const marketRevenue = [
    {
      title: "Date",
      dataIndex: "y_date",
      width: 150,
      fixed: "left",
    },
    {
      title: "user_country",
      dataIndex: "user_country",
      width: 200,
    },
    {
      title: "market",
      dataIndex: "market",
      width: 200,
    },
    {
      title: "Affiliate",
      dataIndex: "affiliate",
      width: 150,
    },
    {
      title: "User Tag",
      dataIndex: "user_tag",
      width: 150,
    },
    {
      title: "Bidded Clicks",
      dataIndex: "bidded_clicks",
      width: 180,
    },
    {
      title: "Bidded Results",
      dataIndex: "bidded_results",
      width: 180,
    },
    {
      title: "Bidded Searches",
      dataIndex: "bidded_searches",
      width: 180,
    },
    {
      title: "Composition",
      dataIndex: "composition",
      width: 150,
    },
    {
      title: "Coverage",
      dataIndex: "coverage",
      width: 150,
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      width: 150,
    },
    {
      title: "Partner rev share",
      dataIndex: "partner_rev_share",
      width: 300,
    },
    {
      title: "Searches",
      dataIndex: "searches",
      width: 150,
    },
    {
      title: "tq_score",
      dataIndex: "tq_score",
      width: 150,
    },
  ];
  console.log("allData1", allData1);
  const scrollObj = { x: 1500, y: 500 };
  return (
    <>
      <DashboardStyled>
        <div className="home-container">
          <DatePicker onChange={onChange} />
          <div className="extension-table-view extension-dashboard-container">
            <h3>Selected Date: {selectedDate}</h3>

            <div>
              <h2>Daily Revenue Info</h2>
              <div>
                <Table
                  pagination={false}
                  dataSource={dailyRevenueInfo}
                  columns={dailyRe}
                  scroll={scrollObj}
                  // rowClassName={(record, index) => `row_selected_priority`}
                />
              </div>
            </div>

            <div>
              <h2>Device Revenue Info</h2>
              <div>
                <Table
                  pagination={false}
                  dataSource={deviceRevenueInfo}
                  columns={deviceRevenue}
                  scroll={scrollObj}
                />
              </div>
            </div>
            <div>
              <h2>Market Revenue Info</h2>
              <div>
                <Table
                  pagination={false}
                  dataSource={marketRevenueInfo}
                  columns={marketRevenue}
                  scroll={scrollObj}
                />
              </div>
            </div>
          </div>
        </div>
      </DashboardStyled>
    </>
  );
};

export default ExtensionDashboard;
