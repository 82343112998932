import fetch from "cross-fetch";
import moment from "moment";
import _ from "lodash";
import { handle401Error } from "../../Scenes/common";
import Axios from "axios";

export const get = async function (url) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    };
    const response = await Axios.get(url, { headers });
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.statusMessages?.[0]);
  }
};
export const put = async function (url, body = {}) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    };
    const response = await Axios.put(url, body, { headers });
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.statusMessages?.[0]);
  }
};
export const post = async function (url, body = {}, secName = "") {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getTokenPost(secName),
    };
    const response = await Axios.post(url, body, { headers });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message == "Unauthenticated.") {
      handle401Error();
    }
    throw new Error(error?.response?.data?.statusMessages?.[0]);
  }
};
export const del = async function (url, body = {}) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    };
    const response = await Axios.delete(url, { headers });
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.statusMessages?.[0]);
  }
};

const getToken = () => {
  const accessToken =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")) &&
    JSON.parse(localStorage.getItem("user"))?.token
      ? JSON.parse(localStorage.getItem("user")).token
      : undefined;
  return accessToken;
};
const getTokenPost = (secName) => {
  const dNAme = secName === "extension" ? "extensionUser" : "user";
  //user
  const accessToken =
    localStorage.getItem(dNAme) &&
    JSON.parse(localStorage.getItem(dNAme)) &&
    JSON.parse(localStorage.getItem(dNAme))?.token
      ? JSON.parse(localStorage.getItem(dNAme)).token
      : undefined;
  return accessToken;
  // return "12855|CpCE51PAwEAdzyufuTonvlSmlGYwz07CW4OA12bN";
};
