
import { useSelector } from "react-redux";
// import { ReactComponent as OrdersIconGray } from "../../Assets/OrdersIconGray.svg";
// import { ReactComponent as SignInIconGray } from "../../Assets/SignInIconGray.svg";

import { RootState } from "../../redux/store";

import { useLocation } from "react-router-dom";

const Cart = (props) => {
    const {
        gotoNextPage,
        handleLogout,
        showLoginBtn,
    } = props;
    const { error, loading, user } = useSelector(({ auth }) => auth);
    const location = useLocation();
    const currentUrl = `${location.pathname}${
        location.search ? location.search : ""
        }`;

    return (
        <div className="menuDropdownMainSection">
            <div
                className="items-list"
                onClick={() => {
                    gotoNextPage("/myProfile");
                }}
            >
                <div className="content-icon">
                    {" "}
                </div>
                <div className="content-text"> My Profile </div>
            </div>
            <div
                className="items-list"
                onClick={() => {
                    gotoNextPage("/admin");
                }}
            >
                <div className="content-icon">
                    {" "}
                </div>
                <div className="content-text"> Admin</div>
            </div>
            <div
                className="items-list"
                onClick={() => {
                    gotoNextPage("/orderHistory");
                }}
            >
                <div className="content-icon">
                    {" "}
                </div>
                <div className="content-text"> Order History </div>
            </div>


            <div
                className="items-list items-list-signout"
                onClick={() => {
                    handleLogout();
                }}
            >
                <div className="content-icon">
                    {" "}
                </div>
                <div className="content-text">
                    {" "}
                Sign Out
              </div>
            </div>
            <style jsx>
                {`.red{
            color: red;
        }
       
        `}
            </style>
        </div>



    );
};

export default Cart;
