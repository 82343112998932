import styled from "styled-components";

export const FooterStyled = styled.div`
  .extension-footer-container {
    background: #f9f9f9;
    padding: 24px 32px;
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.13);
  }
  .extension-footer-top {
    display: flex;
    justify-content: space-between;
  }
  .footer-top-left {
    padding-left: 0px;
    ul {
      list-style-type: none;
      display: flex;
    }
    li {
      padding: 0px 30px;
      color: var(--Shade-100, #000);
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 200% */
    }
    li:not(:last-child) {
      border-right: 1px solid #575757;
    }
  }
  .footer-top-right {
    color: var(--Shade-100, #000);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */

    display: flex;
    ul {
      padding-left: 15px;
      list-style-type: none;
      display: flex;
    }
    li {
      padding: 0px 13px;
    }
  }
  .all-rights-reser {
    border-top: 1px solid #cfd3d7;
    text-align: center;
    padding-top: 20px;
    color: var(--Shade-100, #000);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px; /* 200% */
  }
`;
