import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import heroimage from "../../img/hero-image-2.png";
import frame565 from "../../img/Frame-565.png";
import app_Store from "../../img/App_Store.png";
import google_play from "../../img/Google_play.png";
import { SaleStatusStyled } from "./SaleStatus.styled";
import influencers from "../../img/Influencers.png";
import { Chart } from "react-google-charts";

// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const SaleStatus = (props: any) => {
  const { totalClicks } = props;
  const [addressEnabled, setAddressEnabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { allPriceLineSales } = useSelector((stateA: any) => stateA?.auth);
  console.log("allPriceLineSales", allPriceLineSales);
  // const
  useEffect(() => {
    console.log("vinod");
    getAllSalesData();
  }, []);
  const getAllSalesData = () => {};
  const totalB =
    (allPriceLineSales?.count?.length > 0 &&
      allPriceLineSales?.count[0]?.rateValue) ??
    "00";
  const totalAmount =
    (allPriceLineSales?.salesavg?.length > 0 &&
      allPriceLineSales?.salesavg[0]?.totalAmount) ??
    "00";
  const avgAmount =
    (allPriceLineSales?.salesavg?.length > 0 &&
      allPriceLineSales?.salesavg[0]?.avgAmount.toFixed(2)) ??
    "00";
  const listItems = [
    {
      title: "Clicks",
      value: `${totalClicks}`,
      // subtitle: "23862 Last week",
      // down: "8%",
    },
    { title: "Bookings", value: `${totalB}` },
    { title: "Conversion", value: "0%" },
    {
      title: "All Sales",
      value: `${totalAmount}`,
    },
    {
      title: "Avg. Net Sales",
      value: `${avgAmount}`,
    },
    { title: "Estimated Commission", value: "$00000" },
    // { title: "Overcast", name: "Overcast" },
    // { title: "Castbox", name: "Castbox" },
  ];
  const gotoCartPage = (url: any) => {
    history.push(url);
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
  };
  const data1 = [
    ["Month", "Clicks"],
    ["Mon", 19],
    ["Tue", 14],
    ["Wed", 5],

    ["Thu", 8],
    ["Fri", 2],
    ["Sat", 20],
    ["Sun", 22],
  ];

  const options1 = {
    // title: "Overall Content Reports",
    // vAxis: { title: "Count" },
    // hAxis: { title: "Date" },
    seriesType: "bars",
    colors: ["#0061F6"],
    bar: { groupWidth: "30%" },
  };
  const data2 = [
    ["Month", "Hotel", "Flight", "Car", "Vacation Package"],
    ["Mon", 19, 6, 22, 3],
    ["Tue", 14, 23, 6, 10],
    ["Wed", 5, 6, 9, 15],

    ["Thu", 8, 3, 6, 18],
    ["Fri", 2, 9, 4, 14],
    ["Sat", 20, 5, 14, 5],
    ["Sun", 22, 14, 30, 7],
  ];

  const options2 = {
    // title: "Overall Content Reports",
    // vAxis: { title: "Count" },
    // hAxis: { title: "Date" },
    seriesType: "bars",
    colors: ["#0061F6", "#00AC4F", "#9F5AD3", "#1AB5B0"],
    bar: { groupWidth: "30%" },
  };
  // console.log("allProducts", allProducts);
  return (
    <>
      <SaleStatusStyled>
        <div className="sale-status-content">
          <div className="sale-status-content-header">
            <h2>Sales Stats</h2>
            <div>The stats are displayed for </div>
          </div>

          <div className="all-boxes">
            {listItems?.map((item: any) => {
              return (
                <div className="box-row">
                  <h6>{item.title}</h6>
                  <h4>{item?.value}</h4>
                  <p>
                    {item?.subtitle} <span className="red">{item?.down}</span>
                  </p>
                </div>
              );
            })}
          </div>
          {/* <div className="all-boxes1">
            <div className="box-row1">
              <h5>Clicks Distribution</h5>
              <div className="box-row1-box">
                <div>
                  <h6>Organic</h6>
                  <h4>21882</h4>
                </div>
                <div>
                  <h6>Affiliate</h6>
                  <h4>21882</h4>
                </div>
              </div>
            </div>
            <div className="box-row1-box">
              <div>
                <h6>Organic</h6>
                <h4>21882</h4>
              </div>
              <div>
                <h6>Affiliate</h6>
                <h4>21882</h4>
              </div>
            </div>
            <div className="box-row1-box1"></div>
          </div> */}
          <p className="bottom-text">
            Commissions are a projection and subject to cancellations and final
            clearing amounts from Priceline Partner Network.
          </p>
        </div>
        <div className="sale-status-content">
          <div className="sale-status-content-header">
            <h2>Click & Booking Analysis</h2>
            {/* <div>The stats are displayed for </div> */}
          </div>
          <div className="dashboard-graph-all">
            <div className="dashboard-graph">
              <Chart
                chartType="ComboChart"
                width="100%"
                height="400px"
                data={data1}
                options={options1}
              />
            </div>
            <div className="dashboard-graph">
              <Chart
                chartType="ComboChart"
                width="100%"
                height="400px"
                data={data2}
                options={options2}
              />
            </div>
          </div>
        </div>
      </SaleStatusStyled>
    </>
  );
};

export default SaleStatus;
