import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

const mmsUrl= "https://mms.amos-service.net/api/users";


export const handleGetAuthTokenAPI = createAsyncThunk(
  "auth/handleGetAuthTokenAPI",
  async (body: any) =>
    await post(`https://core.amos-service.net/api/auth/login`, body, "extension")
);


export const handleDailyRevenueAPI = createAsyncThunk(
  "auth/handleDailyRevenueAPI",
  async (body: any) =>
    await post(`${mmsUrl}/y_daily_paged/accounts/274?date=${body?.startDate}`, body,  "extension")
);
export const handleDeviceRevenueAPI = createAsyncThunk(
  "auth/handleDeviceRevenueAPI",
  async (body: any) =>
    await post(`${mmsUrl}/y_device/accounts/274?date=${body?.startDate}`, body,  "extension")
);
export const handleHourlyRevenueAPI = createAsyncThunk(
  "auth/handleHourlyRevenueAPI",
  async (body: any) =>
    await post(`${mmsUrl}/y_hourly/accounts/274?date=${body?.startDate}`, body,  "extension")
);
export const handleMarketRevenueAPI = createAsyncThunk(
  "auth/handleMarketRevenueAPI",
  async (body: any) =>
    await post(`${mmsUrl}/y_market/accounts/274?date=${body?.startDate}`, body, "extension")
);

export const saveYahooDailyReports = createAsyncThunk(
  "auth/saveYahooDailyReports",
  async (body: any) =>
    await post(`${SERVER_IP}/yahoo/saveYahooDailyReports?date=${body?.date}`, body)
);

export const getDateRangeReport = createAsyncThunk(
  "auth/getDateRangeReport",
  async (body: any) =>
    await post(`${SERVER_IP}/yahoo/getDateRangeReport`, body)
);




