import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import heroimage from "../../img/hero-image-2.png";
import frame565 from "../../img/Frame-565.png";
import app_Store from "../../img/App_Store.png";
import google_play from "../../img/Google_play.png";
import { DashboardStyled } from "./DashboardStyled";
import influencers from "../../img/Influencers.png";
import SaleStatus from "./SaleStatus/SaleStatus";
import { SaleStatusStyled } from "./SaleStatus/SaleStatus.styled";
import { getAuthToken } from "./../../Scenes/common";
import { DatePicker, Space } from "antd";
import moment from "moment";
import Loader from "../../components/Common/Loader";
import "antd/dist/antd.css";
import { Table } from "antd";
import {
  getAllPriceLineFeedsApi,
  getAllYahooFeedsApi,
  createUserClicksApi1,
  createUserClicksApi2,
  getAllPricelineSales,
} from "./../../redux/slices/auth/authService";

import {
  handleGetAuthTokenAPI,
  handleDailyRevenueAPI,
  saveYahooDailyReports,
  handleMarketRevenueAPI,
  handleDeviceRevenueAPI,
  getDateRangeReport,
  handleHourlyRevenueAPI,
} from "./../../redux/slices/extensionDashboard/extensionDashboardService";
import { getExtansionTokenPost } from "./../../Scenes/common";
// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const YahooDailyReports = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [totalClicks, setTotalClicks] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { allPriceLineFeedsData, user } = useSelector(({ auth }) => auth);
  const {
    extensionUserInfo,
    dailyRevenueInfo,
    deviceRevenueInfo,
    marketRevenueInfo,
  } = useSelector((stateRR) => stateRR.extensionDashboard);
  console.log("extensionUserInfo", extensionUserInfo);
  console.log("dailyRevenueInfo", dailyRevenueInfo);
  console.log("deviceRevenueInfo", deviceRevenueInfo);
  console.log("marketRevenueInfo", marketRevenueInfo);

  useEffect(() => {
    // getAllClicksInfoData();
    const datav = moment(new Date()).subtract(1, "days").format("YYYY-MM-DD");
    setSelectedDate(datav);

    // getLatestDataHandle(datav);
  }, []);
  useEffect(() => {
    getLatestDataHandle();
  }, [selectedDate]);

  useEffect(() => {
    var linst1 = [];
    var count = 0;
    allPriceLineFeedsData?.data?.map((item) => {
      linst1.push(item);
      count += item?.count;
    });
    setAllData(linst1);
    setTotalClicks(count);
  }, [allPriceLineFeedsData?.data]);

  const getLatestDataHandle = async () => {
    // const dates1 = selectedDate;
    // // dispatch(getAllYahooFeedsApi());
    // if (dates1) {
    //   const body1 = {
    //     email: "vinod@theredclub.com",
    //     password: "Origami@1",
    //   };
    //   if (!getExtansionTokenPost()) {
    //     const resp = await dispatch(handleGetAuthTokenAPI(body1));
    //   }
    //   const resp3 = dispatch(handleDailyRevenueAPI({ startDate: dates1 }));
    //   const resp1 = dispatch(handleMarketRevenueAPI({ startDate: dates1 }));
    //   const resp2 = dispatch(handleDeviceRevenueAPI({ startDate: dates1 }));
    // }
  };

  const gotoCartPage = (url) => {
    history.push(url);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
  };
  const handleSaveReports = async () => {
    setIsLoading(true);
    const resp3 = await dispatch(
      handleDailyRevenueAPI({ startDate: selectedDate })
    );
    const itemsData = resp3?.payload?.data;
    console.log("resp3resp3", itemsData);
    if (itemsData?.length > 0) {
      const newItems = [];
      itemsData.map((itemd) => {
        console.log("itemd", itemd);
        newItems.push({
          ...itemd,
          userId: user?._id,
          report_date: new Date(itemd?.y_date),
        });
      });
      setErrorMessage(
        `Updating ${itemsData?.length} Records to Our Inter Data Base`
      );
      const body = { date: selectedDate, userId: user?._id, data: newItems };
      const resp4 = await dispatch(saveYahooDailyReports(body));
      console.log("resp4resp4resp4resp4", resp4);
      if (resp4?.payload?.success) {
        setIsLoading(false);
        setErrorMessage(
          `Successfully ${itemsData?.length} Updated to Our Inter Data Base`
        );
      }
    } else {
      setIsLoading(false);
      setErrorMessage("No Records found in this Date.");
    }
  };
  const onChange = (datev) => {
    setErrorMessage("");
    const date2 = new Date(datev);
    console.log("date2", date2);
    console.log("onChange", datev);
    const datav = moment(datev || new Date()).format("YYYY-MM-DD");
    console.log("datav", datav);
    setSelectedDate(datav);
  };
  const allData1 = allData?.sort((a, b) => {
    return b.count - a.count;
  });

  console.log("allData1", allData1);
  const scrollObj = { x: 1500, y: 500 };
  return (
    <>
      <DashboardStyled>
        <div className="home-container">
          {isLoading && <Loader />}
          <DatePicker onChange={onChange} />
          <div className="extension-table-view extension-dashboard-container">
            <h3>Selected Date: {selectedDate}</h3>
            {errorMessage && <p>{errorMessage}</p>}
            <div className="save-reports">
              <button onClick={handleSaveReports}>Save Reports to DB</button>
            </div>
            <div>
              <h2>Daily Revenue Info</h2>
              <div></div>
            </div>
          </div>
        </div>
      </DashboardStyled>
    </>
  );
};

export default YahooDailyReports;
