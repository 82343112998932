import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import SignInTab from "./components/SingInTab";
import SignUpTab from "./components/SignUpTab";
import Logo from "../../img/SocialStarhub.png";

import "./styles.css";

const Signin = (props) => {
  // local state

  const [activeTab, setActiveTab] = useState(0);
  const [loginFormTitle, setLoginFormTitle] = useState(
    "Sign in to your account."
  );
  // local state
  let location = useLocation();

  useEffect(() => {
    let urlQueryString = location.search;
    let signInType = new URLSearchParams(urlQueryString).get("returnUrl");
  }, [location.search]);
  const gotoDelivery = () => {
    console.log("vino");
    props?.updateStep("delivery");
  };

  return (
    <div className="loginsignupContainer">
      <div className="data-loginsignupContainer">
        <div className="extension_signin_header">
          <div className="exten-sign-header-logo">
            <img src={Logo} />
          </div>
          <p className="exten-sign-header">Welcome Back to Influ</p>
          <p className="exten-sign-sub-header">
            Please enter Details to Login.
          </p>
        </div>
        <SignInTab gotoDelivery={gotoDelivery} />
      </div>
    </div>
  );
};

export default Signin;
