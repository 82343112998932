import styled from "styled-components";

export const DashboardStyled = styled.div`
  .header-content-top {
    margin-top: 15px;
  }
  .header-content-top-content {
    border-radius: 8px;
    background: var(--Project-White, #fff);
    box-shadow: 2px 0px 24px 5px rgba(86, 59, 255, 0.04);

    width: auto;
    display: inline-flex;
    > div {
      width: 150px;
      text-align: center;
      padding: 12px;
    }
  }
  .extension-dashboard-container {
    width: 100%;
    margin: auto;
    margin-top: 50px;
    h2 {
      margin-bottom: 20px;
    }
    > div {
      margin-bottom: 60px;
    }
  }
  .extension-table-view tr th {
    font-size: 18px !important;
    font-weight: 600 !important;
    border-right: 1px solid #e2e2e2;
    padding-left: 20px !important;
    text-align: center;
  }
  .extension-table-view tr td {
    font-size: 18px !important;
    line-height: 50px !important;
    padding: 5px !important;
    border-right: 1px solid #e2e2e2;
    padding-left: 20px !important;
    text-align: center;
  }
`;
