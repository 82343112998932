import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  handleDailyRevenueAPI,handleGetAuthTokenAPI,
  handleDeviceRevenueAPI,handleHourlyRevenueAPI,
  handleMarketRevenueAPI,getDateRangeReport
  
} from "./extensionDashboardService";

export interface ExtensionDashboardState {
  loading: boolean;
  error: any;
  extensionUserInfo: any;
  dailyRevenueInfo: any;
  deviceRevenueInfo: any;
  marketRevenueInfo: any;
  extensionReports: any;
  
}
const userData: string | null = localStorage.getItem("extensionUser");

const initialState: ExtensionDashboardState = {
  loading: false,
  error: null,
  extensionUserInfo: userData !== null ? JSON.parse(userData) : {},
  dailyRevenueInfo: [],
  deviceRevenueInfo: [],
  marketRevenueInfo: [],
  extensionReports:{}
};

export const dashboardSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state: ExtensionDashboardState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  
    updateErrorMessage: (state: ExtensionDashboardState, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    //getAllPricelineSales
    // builder.addCase(getYahooFeeds.pending, (state: any) => {
    //   state.loading = true;
    //   state.error = null;
    // });
    // builder.addCase(
    //   getYahooFeeds.fulfilled,
    //   (state: ExtensionDashboardState, action: PayloadAction<any>) => {
    //     state.error = null;
    //     state.loading = false;
    //     state.yahooFeeds = action.payload;
    //   }
    // );
    // builder.addCase(
    //   getYahooFeeds.rejected,
    //   (state: ExtensionDashboardState, action: any) => {
    //     state.loading = false;
    //   }
    // );
    //handleGetAuthTokenAPI
    builder.addCase(handleGetAuthTokenAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      handleGetAuthTokenAPI.fulfilled,
      (state: ExtensionDashboardState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.extensionUserInfo = action.payload;
        localStorage.setItem("extensionUser", JSON.stringify(action.payload));

      }
    );
    builder.addCase(
      handleGetAuthTokenAPI.rejected,
      (state: ExtensionDashboardState, action: any) => {
        state.loading = false;
      }
    );
    //handleDailyRevenueAPI
    builder.addCase(handleDailyRevenueAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      handleDailyRevenueAPI.fulfilled,
      (state: ExtensionDashboardState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.dailyRevenueInfo = action.payload?.data;
      }
    );
    builder.addCase(
      handleDailyRevenueAPI.rejected,
      (state: ExtensionDashboardState, action: any) => {
        state.loading = false;
      }
    );
    builder.addCase(handleDeviceRevenueAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      handleDeviceRevenueAPI.fulfilled,
      (state: ExtensionDashboardState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.deviceRevenueInfo = action.payload;
      }
    );
    builder.addCase(
      handleDeviceRevenueAPI.rejected,
      (state: ExtensionDashboardState, action: any) => {
        state.loading = false;
      }
    );
    builder.addCase(handleMarketRevenueAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      handleMarketRevenueAPI.fulfilled,
      (state: ExtensionDashboardState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.marketRevenueInfo = action.payload;
      }
    );
    builder.addCase(
      handleMarketRevenueAPI.rejected,
      (state: ExtensionDashboardState, action: any) => {
        state.loading = false;
      }
    );
    //getDateRangeReport
    builder.addCase(getDateRangeReport.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getDateRangeReport.fulfilled,
      (state: ExtensionDashboardState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.extensionReports = action.payload;
      }
    );
    builder.addCase(
      getDateRangeReport.rejected,
      (state: ExtensionDashboardState, action: any) => {
        state.loading = false;
      }
    );
    //extensionReports
  },
});

export const {
  setLoading,
  updateErrorMessage,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
