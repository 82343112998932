import styled from "styled-components";

export const AddLinksStyled = styled.div`
  .links-top-sec-heas h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px; /* 135% */

    color: #000;
  }
  .links-sec-full-container {
    border-radius: 16px;
    border: 1px solid #cfd3d7;
    background: #fcfcfc;
    padding: 24px;
    margin-bottom: 40px;
    h4 {
      color: var(--Shade-100, #000);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 177.778% */

      span {
        color: var(--Neutral-700, #7f8b93);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }
    }
    input {
      border-radius: 18px;
      border: none;
      width: 100%;
      height: 55px;
      padding-left: 5px;
      padding-right: 10px;
      background: #fff;
      color: var(--Shade-100, #000);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    input:hover,
    input:focus-visible,
    input:active {
      border: none;
    }
  }
  .add-link-item-bottom {
    display: flex;
    border-radius: 18px;
    border: 1px solid #cfd3d7;
    padding-left: 16px;
    background: #fff;
    img {
      margin-right: 10px;
    }
  }
`;
