import React, { useEffect, useState } from "react";
import { RecentPodcastStyled } from "./RecentPodcasts.styled";
import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import delete_img from "../../img/delete-bin-fill.png";
import camera_line from "../../img/camera-line.png";
import {
  saveUserProdCasts,
  updateUserPodcast,
  getAllProdCasts,
  getProdcastById,
  getSignedUrlApi,
} from "../../redux/slices/auth/authService";
import Loader from "./../../components/Common/Loader";
import CustomModal from "./../../components/CustomModel";
import ImageUploadModel from "./ImageUploadModel/ImageUploadModel";
// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const RecentPodcasts = () => {
  const listItems = [
    { podcastTitle: "", podcastLink: "", image: "" },
    { podcastTitle: "", podcastLink: "", image: "" },
    { podcastTitle: "", podcastLink: "", image: "" },
  ];

  const [addressEnabled, setAddressEnabled] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [listOfItems, setListOfItems] = useState([]);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showImageMoodel, setShowImageMoodel] = useState(false);
  const [tempImageData, setTempImageData] = useState({});
  const [uploadImageIndex, setUploadImageIndex] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();
  const { allProdCasts, user } = useSelector(({ auth }) => auth);

  // const { allProducts } = useSelector(({ productCart }) => productCart);
  useEffect(() => {
    console.log("vinod", listItems);
    getProdcastByIdData();

    setListOfItems(listItems);
  }, []);
  useEffect(() => {
    console.log("allProdCasts", allProdCasts);
    if (allProdCasts?.data?._id) {
      // const finalData = allProdCasts?.data[allProdCasts?.data?.length - 1];
      const finalData = allProdCasts?.data;
      console.log("finalData", finalData);
      // const prevV = { ...formData };
      // prevV[name] = value;
      let prevList = [];
      finalData?.items?.map((item) => {
        prevList.push({ ...item });
        console.log("item", item);
      });
      setListOfItems(prevList);
      setFormData(finalData);
    }
  }, [allProdCasts]);
  const getProdcastByIdData = async () => {
    await dispatch(getSignedUrlApi());
    await dispatch(getProdcastById({ userId: user?._id }));
  };
  const addNewRecord = () => {
    const pplistItems = [...listOfItems];
    pplistItems.push({ podcastTitle: "", podcastLink: "", image: "" });
    console.log("pplistItems", pplistItems);
    setListOfItems(pplistItems);
  };

  const handleItemDelete = (index) => {
    const pplistItems = [...listOfItems];
    // pplistItems.push({);
    pplistItems.splice(index, 1);
    console.log("pplistItems", pplistItems);
    setListOfItems(pplistItems);
  };
  const handleChange = (e, index) => {
    console.log("index", index);
    const { name, value } = e.target;
    // const prevV = { ...formData };
    // prevV[name] = value;
    // setFormData(prevV);

    const pplistItems = [...listOfItems];
    pplistItems[index][name] = value;
    setListOfItems(pplistItems);
  };
  const handleSubmit1 = async () => {
    setShowWarningMessage(true);
  };

  const handleSubmit = async () => {
    // delete formData?.event_time;
    // delete formData?.__v;
    // delete formData?._id;
    setIsLoader(true);
    const body = {
      items: listOfItems,
      userId: user?._id,
    };

    if (formData?._id) {
      body._id = formData?._id;
      await dispatch(updateUserPodcast(body));
      await getProdcastByIdData();
      setIsLoader(false);
      setShowSuccessMessage(true);
    } else {
      await dispatch(saveUserProdCasts(body));
      await getProdcastByIdData();
      setIsLoader(false);
      setShowSuccessMessage(true);
    }
  };
  const handleImageClick = (indexww) => {
    // setTempImageData(obj);
    setUploadImageIndex(indexww);
    setShowImageMoodel(true);
  };
  const handleCloseImage = () => {
    setShowImageMoodel(false);
  };
  const handleImageUploadSuccess = (imgName) => {
    handleCloseImage();
    const pplistItems = [...listOfItems];
    pplistItems[uploadImageIndex][
      "image"
    ] = `https://socialstarhub-extension-images.s3.amazonaws.com/${imgName}`;
    setListOfItems(pplistItems);
  };
  console.log("formData", formData);
  console.log("listOfItems", listOfItems);

  return (
    <>
      <RecentPodcastStyled>
        <div>
          <CustomModal
            show={showWarningMessage}
            titleText={"Are you sure do you want to Add links?"}
            deleteText="ADD"
            cancelText=""
            onDelete={() => {
              setShowWarningMessage(false);
              handleSubmit();
              // history.push("/jobcardsummary");
            }}
          />

          <CustomModal
            show={showSuccessMessage}
            titleText={"Your links has been successfully added."}
            deleteText="OKAY"
            cancelText=""
            onDelete={() => {
              setShowSuccessMessage(false);
              // history.push("/jobcardsummary");
            }}
          />
          <ImageUploadModel
            show={showImageMoodel}
            handleClose={handleCloseImage}
            handleImageUploadSuccess={handleImageUploadSuccess}
          />

          {isLoader && <Loader />}
          <div className="admin-add-links-sec">
            <div className="daily-quote-header-text">
              <div>
                <h2> Recent Podcasts</h2>
              </div>
              <div>
                <button onClick={addNewRecord}>+ Add New Podcast Link</button>
              </div>
            </div>
            <div className="daily-quote-sec">
              {listOfItems?.map((item, index) => {
                const currentNo = index + 1;
                const imgUrl = item?.image ? `${item?.image}` : null;
                return (
                  <div className="add-link-item">
                    <div className="add-link-item-item-sec">
                      <h4>Podcast Link {currentNo}</h4>
                      {index > 2 && (
                        <span
                          onClick={() => {
                            handleItemDelete(index);
                          }}
                        >
                          <img src={delete_img} />
                        </span>
                      )}
                    </div>

                    <div className="add-link-item-bottom">
                      <div className="row-left">
                        {imgUrl ? (
                          <div
                            className="image-link-sec-item"
                            onClick={() => {
                              handleImageClick(index);
                            }}
                          >
                            {/* <input type="file" id="myFile" name="filename" /> */}
                            <img src={imgUrl} />
                          </div>
                        ) : (
                          <div
                            className="image-link-sec-item image-link-sec-item-new"
                            onClick={() => {
                              handleImageClick(index);
                            }}
                          >
                            {/* <input type="file" id="myFile" name="filename" /> */}
                            <img src={camera_line} />
                            <div>
                              <span>
                                Upload Thumbnail
                                <span className="manditary-field">*</span>
                              </span>{" "}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row-right">
                        <div>
                          <p>
                            {" "}
                            Podcast Title{" "}
                            <span className="manditary-field">*</span>
                          </p>
                          <input
                            className="input-box"
                            name={`podcastTitle`}
                            value={item.podcastTitle}
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                            placeholder={`Enter Title`}
                          />
                        </div>
                        <div className="RecentPodcasts-link-box">
                          <p>
                            {" "}
                            Podcast Link{" "}
                            <span className="manditary-field">*</span>
                          </p>
                          <input
                            className="input-box"
                            name={`podcastLink`}
                            value={item.podcastLink}
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                            placeholder={`Enter Link`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="submit-btn-links">
              <button onClick={handleSubmit1}>ADD</button>
            </div>
          </div>

          <div></div>
        </div>
      </RecentPodcastStyled>
    </>
  );
};

export default RecentPodcasts;
