import styled from "styled-components";

export const DailyQuoteStyled = styled.div`
  .daily-quote-sec {
    border-radius: 16px;
    border: 1px solid #cfd3d7;

    background: #fcfcfc;
    padding: 20px;
    h4 {
      // margin-top: 20px;
      color: var(--Shade-100, #000);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 187.5% */
    }
    p {
      color: #9a9a9a;

      text-align: center;
      // font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .daily-quote-sec textarea {
    width: 100%;
    background-color: initial;
    border: none;
    border-top: 1px solid #cfd3d7;
    border-radius: 0px;
    /* font-family: Montserrat; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 120px;
    line-height: 28px;
    padding: 10px 15px;
    text-align: center;
  }
  .admin-add-links-sec {
    width: 100%;
  }
  .daily-quote-header-text {
    margin-bottom: 20px;
  }
  .content-length-section {
    bottom: -18px;
    float: right;
    right: 2px;
    color: #979797;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }
  .prev-quates {
    h5 {
      color: var(--Shade-100, #000);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 177.778% */
      margin-bottom: 24px;
    }
    .prev-quates-sec {
      border-top: 1px solid #cfd3d7;
      padding-top: 24px;
    }
    h6 {
      color: var(--Shade-100, #000);
      text-align: center;
      // font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 187.5% */
    }
    .prev-quates-list-row-top {
      display: flex;
      gap: 8%;
    }

    .prev-quates-list-row-top p {
      color: #9a9a9a;

      text-align: center;
      // font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .prev-quates-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  .prev-quates-list-row-bottom {
    border-top: 1px solid #cfd3d7;
    padding-top: 20px;
  }
  .daily-quote-sec {
    margin-top: 40px;
  }
  .prev-quates-list-row {
    border-radius: 16px;
    border: 1px solid #cfd3d7;

    background: #fcfcfc;
    padding: 16px;
  }
  .prev-qua-img {
    text-align: right;
  }
  .daily-quote-sec-dte {
    text-align: center;
    margin-bottom: 20px;
  }
  .submit-btn-links-add {
    text-align: center;
    margin-top: 30px;
    button {
      background-color: #ff5733;
      border-radius: 11px;
      color: #fff;
      /* font-family: Oswald; */
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      height: 50px;
      letter-spacing: 1.25px;
      line-height: 28px;
      width: 300px;
      text-align: center;
      text-transform: uppercase;
      border: none;
      cursor: pointer;
    }
  }
  .submit-btn-links-save {
    text-align: center;
    margin-top: 30px;
    button {
      background-color: #ff5733;
      border-radius: 11px;
      color: #fff;
      /* font-family: Oswald; */
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      height: 50px;
      letter-spacing: 1.25px;
      line-height: 28px;
      width: 70%;
      text-align: center;
      text-transform: uppercase;
      border: none;
      cursor: pointer;
    }
  }
  .add-link-item-item-sec-dalete {
    text-align: right;
    margin-top: -45px;
    margin-bottom: 20px;
    span {
      cursor: pointer;
    }
  }
`;
