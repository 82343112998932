import React, { useEffect, useState } from "react";
import { MyProfileInfoStyled } from "./MyProfileInfo.styled";
import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
// import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import heroimage from "../../img/hero-image-2.png";
import frame565 from "../../img/Frame-565.png";
import CustomModal from "./../../components/CustomModel";
import ChangePassword from "./ChangePassword";
import {
  updateUserDetails,
  getUserDetailsById,
} from "../../redux/slices/auth/authService";
const MyProfileInfo = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [signUpData, setSignUpData] = useState({});
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showPassWordModel, setShowPassWordModel] = useState(false);
  const [showSuccessPass, setShowSuccessPass] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  // const { allProducts } = useSelector(({ productCart }) => productCart);
  useEffect(() => {
    console.log("vinod");
    getUserDetailsByIdD();
  }, []);
  useEffect(() => {
    updateUSerDetailstoState();
  }, [user]);

  const updateUSerDetailstoState = () => {
    console.log("user 333", user);
    const addr = { ...signUpData };
    addr.fullName = user?.fullName;
    addr.influenceCode = user?.influenceCode;
    addr.phoneNumber = user?.phoneNumber;
    addr.email = user?.email;
    setSignUpData(addr);
    // user
  };

  const handleChangeValue = (e) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    if (name == "phoneNumber" || name == "officeNo") {
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
    }
  };
  const getUserDetailsByIdD = () => {
    dispatch(getUserDetailsById({ email: user?.email }));
  };
  const handleLogout = () => {
    localStorage.clear();
    history.push("/signIn");
    window.location.reload();
  };
  const handleEdit = () => {
    setIsEdit(true);
  };
  const handleCancelEdit = () => {
    setIsEdit(false);
  };
  const handleUpdateEdit = () => {
    if (signUpData?.fullName && signUpData?.phoneNumber) {
      // setIsEdit(true);
      setShowWarningMessage(true);
    }
  };
  const handleUpdateValues = async () => {
    const body = {
      fullName: signUpData?.fullName,
      phoneNumber: signUpData?.phoneNumber,
      email: user?.email,
    };
    await dispatch(updateUserDetails(body));
    setIsEdit(false);
    getUserDetailsByIdD();
  };
  const isValidFn = () => {
    const returnF =
      !signUpData?.fullName ||
      !signUpData?.phoneNumber ||
      signUpData?.phoneNumber?.length < 10;
    return returnF;
  };
  const handleClosePassword = () => {
    setShowPassWordModel(false);
  };
  const handleOpenPassword = () => {
    setShowPassWordModel(true);
  };
  const changePassSuccess = () => {
    setShowPassWordModel(false);
    setShowSuccessPass(true);
  };
  // console.log("allProducts", allProducts);
  return (
    <>
      <MyProfileInfoStyled>
        <div>
          <ChangePassword
            show={showPassWordModel}
            handleClose={handleClosePassword}
            changePassSuccess={changePassSuccess}
          />
          <CustomModal
            show={showWarningMessage}
            titleText={"Are you sure do you want to update account details?"}
            deleteText="Update"
            cancelText=""
            onDelete={() => {
              setShowWarningMessage(false);
              handleUpdateValues();
              // history.push("/jobcardsummary");
            }}
          />
          <CustomModal
            show={showSuccessMessage}
            titleText={"Your account details has been successfully updated."}
            deleteText="OKAY"
            cancelText=""
            onDelete={() => {
              setShowSuccessMessage(false);
              // history.push("/jobcardsummary");
            }}
          />
          <CustomModal
            show={showSuccessPass}
            titleText={"Your password has been successfully changed."}
            deleteText="LOGIN"
            cancelText=""
            onDelete={() => {
              setShowSuccessPass(false);
              handleLogout();
              // history.push("/jobcardsummary");
            }}
          />
          <div className="admin-add-links-sec1">
            <div className="top-header-sec-my-p">
              <h2>My Account</h2>
            </div>

            {isEdit ? (
              <>
                <div className="profle-body-sec-container">
                  <div className="edit-account-info">
                    <h4>Edit Account Details</h4>
                    <div>
                      <div className="SignupContainer edit-account-Container">
                        <div>
                          <Form.Group
                            className="signup-md1"
                            size="lg"
                            controlId="email"
                          >
                            <Form.Label>Full Name*</Form.Label>
                            <Form.Control
                              autoFocus
                              name="fullName"
                              type="text"
                              placeholder="Enter Full Name"
                              value={signUpData?.fullName}
                              onChange={(e) => handleChangeValue(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              {!signUpData?.fullName ? (
                                <>Please Enter Valid Full Name</>
                              ) : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="signup-md1-left"
                            size="lg"
                            controlId="email"
                          >
                            <Form.Label>Email Address*</Form.Label>
                            <Form.Control
                              type="text"
                              name="email"
                              disabled={true}
                              value={signUpData?.email}
                            />
                          </Form.Group>
                        </div>
                        <div>
                          <Form.Group
                            className="signup-md1"
                            size="lg"
                            controlId="email"
                          >
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={10}
                              name="phoneNumber"
                              placeholder="Enter Phone Number"
                              value={signUpData?.phoneNumber}
                              onChange={(e) => handleChangeValue(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              {!signUpData?.phoneNumber ? (
                                <>Please Enter Valid Phone Number</>
                              ) : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="signup-md1"
                            size="lg"
                            controlId="influenceCode"
                          >
                            <Form.Label>InfluenceSphere Code*</Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={50}
                              name="influenceCode"
                              value={signUpData?.influenceCode}
                              disabled={true}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-actions-btns">
                  <button className="cancel-btn" onClick={handleCancelEdit}>
                    Cancel
                  </button>
                  <button
                    className="update-btn"
                    disabled={isValidFn()}
                    onClick={handleUpdateEdit}
                  >
                    Update
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="profle-body-sec-container">
                  <div className="edit-icon-sec-val-btn">
                    <button onClick={handleEdit}>EDIT</button>
                  </div>
                  <div className="profile-info-sec">
                    <div className="logo-bg-profile">
                      <span>
                        {user?.fullName?.substring(0, 2)?.toUpperCase()}
                      </span>
                    </div>
                    <p className="p-user-name">{user?.fullName}</p>
                    <p className="p-user-info">{user?.email}</p>
                    <p className="p-user-info">{user?.phoneNumber}</p>
                    <p className="p-user-info">{user?.influenceCode}</p>
                    <div className="profile-change-pass-btn">
                      <button onClick={handleOpenPassword}>
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            <br />
            <div onClick={handleLogout}> Log Out</div>

            {/* <div>
              <div>
                {listItems?.map((item) => {
                  return (
                    <div className="add-link-item">
                      <p>{item?.title}</p>
                      <p>{item?.name}</p>
                    </div>
                  );
                })}
              </div>
            </div> */}
          </div>

          <div></div>
        </div>
      </MyProfileInfoStyled>
    </>
  );
};

export default MyProfileInfo;
