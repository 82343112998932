import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import heroimage from "../../img/hero-image-2.png";
import frame565 from "../../img/Frame-565.png";
import {
  getAllEvents,
  saveUserEvents,
  updateUserEvents,
  getEventsById,
} from "../../redux/slices/auth/authService";
import { AddLinksStyled } from "./AddLinks.styled";

import iconGoogle from "./../../assets/icons/Google.svg";
import iconSnapchat from "./../../assets/icons/Snapchat.svg";
import iconYoutube from "./../../assets/icons/Youtube.svg";
import iconInstgram from "./../../assets/icons/Instgram.svg";
import iconTikTok from "./../../assets/icons/TikTok.svg";

import iconApple from "./../../assets/icons/Apple.svg";
import iconSpotify from "./../../assets/icons/Spotify.svg";
import iconRSS from "./../../assets/icons/RSS.svg";
import iconBullhorn from "./../../assets/icons/Bullhorn.svg";
import iconOvercast from "./../../assets/icons/Overcast.svg";
import iconCastbox from "./../../assets/icons/Castbox.svg";
import iconPocketCasts from "./../../assets/icons/pocketcasts.svg";
import iconCastro from "./../../assets/icons/Castro.svg";
import iconPodbean from "./../../assets/icons/Podbean.svg";
// import iconPodbean from "./../../assets/icons/Podbean.svg";
import iconPodcastGuru from "./../../assets/icons/PodcastGuru.svg";
// import iconCastro from "./../../assets/icons/Castro.svg";
// `./images/icons/${key}.svg`;
import Loader from "./../../components/Common/Loader";
const mediaLinks = [
  { title: "Snapchat", name: "Snapchat", src: iconSnapchat },
  { title: "Youtube", name: "Youtube", src: iconYoutube },
  { title: "Instagram", name: "Instgram", src: iconInstgram },
  { title: "Tiktok", name: "TikTok", src: iconTikTok },
  { title: "Your Website", name: "yourwebsite", src: iconTikTok },
];
const podcastLinks1 = [
  { title: "Apple Podcasts", name: "Apple", src: iconApple },
  { title: "Spotify", name: "Spotify", src: iconSpotify },
  { title: "Google Podcasts", name: "Google", src: iconGoogle },
  { title: "RSS", name: "RSS", src: iconRSS },
  { title: "Bullhorn", name: "Bullhorn", src: iconBullhorn },
  { title: "Overcast", name: "Overcast", src: iconOvercast },
  { title: "Castbox", name: "Castbox", src: iconCastbox },
  { title: "Pocket Casts", name: "PocketCasts", src: iconPocketCasts },
  { title: "Castro", name: "Castro", src: iconCastro },
  { title: "Podbean", name: "Podbean", src: iconPodbean },
  { title: "Podcast Guru", name: "PodcastGuru", src: iconPodcastGuru },
];
// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const AddLinks = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [socialMediaLinks, setSocialMediaLinks] = useState(mediaLinks);
  const [prodcastLinks, setProdcastLinks] = useState(podcastLinks1);
  const history = useHistory();
  const dispatch = useDispatch();
  const { allEvents, user } = useSelector(({ auth }) => auth);

  // const { allProducts } = useSelector(({ productCart }) => productCart);
  useEffect(() => {
    console.log("vinod");

    dispatch(getEventsById({ userId: user?._id }));
  }, []);
  useEffect(() => {
    console.log("allEvents", allEvents);
    if (allEvents?.data) {
      // const finalData = allEvents?.data[allEvents?.data?.length - 1];
      const finalData = allEvents?.data;
      console.log("finalData", finalData);
      const prevV = { ...finalData };
      // prevV[name] = value;
      setFormData(prevV);
    }
  }, [allEvents]);
  const handleChange = (e, index) => {
    const { name, value } = e.target;

    const prevV = { ...formData };
    prevV[name] = value;
    setFormData(prevV);
  };
  const handleSubmit = async () => {
    var formData1 = { ...formData };
    try {
      if (formData1?.event_time) {
        delete formData1?.event_time;
      }
      if (formData1?.__v) {
        delete formData1?.__v;
      }
    } catch (e) {
      console.log("error", e);
    }
    console.log("formDataformData", formData);
    console.log("formData1formData1", formData1);
    // delete formData?._id;
    // formData.
    formData1.userId = user?._id;
    setIsLoader(true);
    if (formData1?._id) {
      await dispatch(updateUserEvents(formData1));
      await dispatch(getEventsById({ userId: user?._id }));
      setIsLoader(false);
    } else {
      await dispatch(saveUserEvents(formData1));
      await dispatch(getEventsById({ userId: user?._id }));
      setIsLoader(false);
    }
  };
  console.log("formData", formData);
  const nameS = "searchLink";
  return (
    <>
      <AddLinksStyled>
        <div>
          {isLoader && <Loader />}
          <div className="admin-add-links-sec-container">
            <div className="links-top-sec-heas">
              <h2> Search Link</h2>
            </div>
            <div className="links-sec-full-container">
              <h4>Main page search Link</h4>
              <div className="links-sec-full">
                <div className="add-link-item">
                  {/* <p>{item?.title}</p> */}
                  <div className="add-link-item-bottom">
                    <input
                      name={nameS}
                      value={formData[nameS]}
                      onChange={(e) => {
                        handleChange(e, "12");
                      }}
                      className="link-input-sec-box"
                      placeholder={`Enter Search Link`}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="links-top-sec-heas">
              <h2> Links</h2>
            </div>
            <div className="links-sec-full-container">
              <h4>
                Social Media Links <span>(You can add up to 7 Links)</span>
              </h4>
              <div className="links-sec-full">
                {socialMediaLinks?.map((item, index) => {
                  const linksrc = `icon${item?.name}`;
                  return (
                    <div className="add-link-item">
                      {/* <p>{item?.title}</p> */}
                      <div className="add-link-item-bottom">
                        <img src={item?.src} />
                        <input
                          name={item?.name}
                          value={formData[item?.name]}
                          onChange={(e) => {
                            handleChange(e, index);
                          }}
                          className="link-input-sec-box"
                          placeholder={`Enter ${item?.title} Link`}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="links-sec-full-container">
              <h4>Podcast Links</h4>
              <div className="links-sec-full">
                {prodcastLinks?.map((item, index) => {
                  return (
                    <div className="add-link-item">
                      {/* <p>{item?.title}</p> */}
                      <div className="add-link-item-bottom">
                        <img src={item?.src} />
                        <input
                          name={item?.name}
                          value={formData[item?.name]}
                          onChange={(e) => {
                            handleChange(e, index);
                          }}
                          className="link-input-sec-box"
                          placeholder={`Enter ${item?.title} Link`}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="submit-btn-links">
              <button onClick={handleSubmit}>ADD</button>
            </div>
          </div>

          <div></div>
        </div>
      </AddLinksStyled>
    </>
  );
};

export default AddLinks;
