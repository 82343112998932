import { configureStore } from "@reduxjs/toolkit";

// reducers
import appReducer from "../slices/app/appSlice";
import authReducer from "../slices/auth/authSlice";
import extensionDashboardReducer from "../slices/extensionDashboard/extensionDashboardSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    extensionDashboard: extensionDashboardReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
