import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  // async (body:any) => {
  //   return await post(`${SERVER_IP}/auth/login`, body);
  // }
  async (body: any) => await post(`${SERVER_IP}/auth/signin`, body)
);

export const signUpUser = createAsyncThunk(
  "auth/signUpUser",
  async (body: any) => await post(`${SERVER_IP}/auth/signup`, body)
);
export const updateUserDetails = createAsyncThunk(
  "auth/updateUserDetails",
  async (body: any) => await put(`${SERVER_IP}/auth/update`, body)
);

export const getUserDetailsById = createAsyncThunk(
  "auth/getUserDetailsById",
  async (body: any) => await post(`${SERVER_IP}/auth/getUserDetailsById`, body)
);

export const updateUserEvents = createAsyncThunk(
  "auth/updateUserEvents",
  async (body: any) => await put(`${SERVER_IP}/event/updateEvent`, body)
);

export const saveUserEvents = createAsyncThunk(
  "auth/saveUserEvents",
  async (body: any) => await post(`${SERVER_IP}/event/create`, body)
);

export const getAllEvents = createAsyncThunk(
  "auth/getAllEvents",
  async () => await get(`${SERVER_IP}/event/all`)
);
export const getEventsById = createAsyncThunk(
  "auth/getEventsById",
  async (body: any) => await post(`${SERVER_IP}/event/getEventsById`, body)
);

export const saveUserProdCasts = createAsyncThunk(
  "auth/saveUserProdCasts",
  async (body: any) => await post(`${SERVER_IP}/prodcast/create`, body)
);

export const getAllProdCasts = createAsyncThunk(
  "auth/getAllProdCasts",
  async () => await get(`${SERVER_IP}/prodcast/all`)
);
export const getProdcastById = createAsyncThunk(
  "auth/getProdcastById",
  async (body: any) => await post(`${SERVER_IP}/prodcast/getProdcastById`, body)
);

export const updateUserPodcast = createAsyncThunk(
  "auth/updateUserPodcast",
  async (body: any) => await put(`${SERVER_IP}/prodcast/updatePodcast`, body)
);

export const saveUserDailyQuotes = createAsyncThunk(
  "auth/saveUserDailyQuotes",
  async (body: any) => await post(`${SERVER_IP}/dailyQuote/create`, body)
);

export const getAllDailyQuotes = createAsyncThunk(
  "auth/getAllDailyQuotes",
  async () => await get(`${SERVER_IP}/dailyQuote/all`)
);

export const updateUserDailyQuotes = createAsyncThunk(
  "auth/updateUserDailyQuotes",
  async (body: any) => await put(`${SERVER_IP}/dailyQuote/update`, body)
);
export const getDailyQuoteById = createAsyncThunk(
  "auth/getDailyQuoteById",
  async (body: any) =>
    await post(`${SERVER_IP}/dailyQuote/getDailyQuoteById`, body)
);
export const getSignedUrlApi = createAsyncThunk(
  "auth/getSignedUrlApi",
  async () => await get(`${SERVER_IP}/prodcast/getSignedUrl`)
);
