import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { signUpUser } from "../../../../redux/slices/auth/authService";
import { useHistory, useLocation } from "react-router-dom";

// import "./styles.css";
const initVal = {
  fullName: undefined,
  phoneNumber: undefined,
  email: undefined,
  password: undefined,
  conformPassword: undefined,
  influenceCode: undefined,
};

const initValError = {
  fullName: false,
  phoneNumber: false,
  email: false,
  password: false,
  conformPassword: false,
  influenceCode: false,
};

const initValErrorData = {
  fullName: "Please Enter Full Name",
  phoneNumber: "Please Enter Phone number",
  email: "Please Enter valid email",
  password: "Please Enter Password",
  conformPassword: "Please Enter Conform Password",
  influenceCode: "Please Enter Influence Code",
};

const SignUpTab = () => {
  const [signUpData, setSignUpData] = useState(initVal);
  const [errorData, setErrorData] = useState(initValError);
  const [errorDataValue, setErrorDataValue] = useState(initValErrorData);
  const [isCheckedBox, setIsCheckedBox] = useState(false);
  const { error, loading, user } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const [passError, setPassError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?._id) {
      history.push("/dailyQuote");
    } else {
      // history.push("/signIn");
    }
  }, [user]);
  const changeCheckBox = (e) => {
    setIsCheckedBox(e.target.checked);
  };
  const handleChangeValue = (e) => {
    setPassError(null);
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };
    if (name == "phoneNumber" || name == "officeNo") {
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
      }
    } else if (name === "email" || name == "officeEmail") {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(value).toLowerCase())) {
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      } else {
        setErrorData({
          ...addr_error,
          [name]: true,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
    }
  };
  const createAccountData = () => {
    setPassError(null);
    var isValid = true;
    var errornew = { ...errorData };
    for (var key in signUpData) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    console.log("errornew", errornew);
    setErrorData({ ...errornew });

    if (signUpData?.password !== signUpData?.conformPassword) {
      isValid = false;
      setPassError(
        "Password and Confirm Password fields value must be matched."
      );
    } else if (signUpData?.password.length < 6) {
      isValid = false;
      setPassError("Password requires 6 characters minimum.");
    }

    if (isValid) {
      makeSignUpCall(signUpData);
      console.log("signUpData", signUpData);
    }
  };
  const makeSignUpCall = async (data) => {
    const resp = await dispatch(signUpUser(data));
    console.log("vinodddd", resp);
    if (resp?.payload?.error) {
      setPassError(resp?.payload?.error);
    }
  };
  const validationCheck = () => {
    var formValid = false;
    console.log("signUpData", signUpData);
    for (var key in signUpData) {
      if (signUpData[key] === "" || !signUpData[key]) {
        formValid = true;
      }
    }
    if (!isCheckedBox) {
      formValid = true;
    }
    return formValid;
  };

  console.log("errorData?.email", errorData, error);
  return (
    <div className="SignupContainer">
      <Form.Group className="signup-md1" size="lg" controlId="email">
        <Form.Label>Full Name*</Form.Label>
        <Form.Control
          autoFocus
          name="fullName"
          type="text"
          placeholder="Enter Full Name"
          value={signUpData?.fullName}
          onChange={(e) => handleChangeValue(e)}
        />
        <Form.Control.Feedback type="invalid">
          {errorData?.fullName ? <>{errorDataValue?.fullName}</> : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="signup-md1-left" size="lg" controlId="email">
        <Form.Label>Email Address*</Form.Label>
        <Form.Control
          type="text"
          name="email"
          placeholder="Enter Email Address"
          value={signUpData?.email}
          onChange={(e) => handleChangeValue(e)}
        />
        <Form.Control.Feedback type="invalid">
          {errorData?.email ? <>{errorDataValue?.email}</> : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="signup-md1" size="lg" controlId="email">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="text"
          maxLength={10}
          name="phoneNumber"
          placeholder="Enter Phone Number"
          value={signUpData?.phoneNumber}
          onChange={(e) => handleChangeValue(e)}
        />
        <Form.Control.Feedback type="invalid">
          {errorData?.phoneNumber ? <>{errorDataValue?.phoneNumber}</> : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="signup-md1" size="lg" controlId="influenceCode">
        <Form.Label>InfluenceSphere Code*</Form.Label>
        <Form.Control
          type="text"
          maxLength={50}
          placeholder="Enter Code"
          name="influenceCode"
          value={signUpData?.influenceCode}
          onChange={(e) => handleChangeValue(e)}
        />
        <Form.Control.Feedback type="invalid">
          {errorData?.influenceCode ? (
            <>{errorDataValue?.influenceCode}</>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="signup-md1" size="lg" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          name="password"
          placeholder="Password"
          isInvalid={errorData?.password}
          value={signUpData?.password}
          onChange={(e) => handleChangeValue(e)}
        />
        <Form.Control.Feedback type="invalid">
          {errorData?.password ? <>{errorDataValue?.password}</> : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="signup-md1-left" size="lg" controlId="password">
        <Form.Label>Conform Password</Form.Label>
        <Form.Control
          type="password"
          name="conformPassword"
          placeholder="Conform Password"
          value={signUpData?.conformPassword}
          onChange={(e) => handleChangeValue(e)}
        />
      </Form.Group>
      <div className="account-set-up-sec-exten">
        <input
          type="checkbox"
          checked={isCheckedBox}
          onChange={changeCheckBox}
        />{" "}
        {` `}
        By creating an account you agree to the{" "}
        <span>
          <a>Terms & Conditions</a>
        </span>{" "}
        and our{" "}
        <span>
          <a>Privacy policy</a>
        </span>
      </div>
      {passError && (
        <p className="errorMessage errorMessage-signup">{passError}</p>
      )}
      {error && !passError && (
        <p className="errorMessage errorMessage-signup">{error}</p>
      )}
      <button
        disabled={validationCheck()}
        onClick={() => {
          createAccountData();
        }}
        className="sign-up-btn-sec-exten"
        block
        size="lg"
        type="submit"
      >
        {loading ? "Loading" : "Sign Up"}
      </button>
      <div className="dont-have-acc-sec">
        <p>
          Already have an account?{` `}
          <span>
            <a href="/signin">Log in</a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default SignUpTab;
